import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate as validateUuid } from 'uuid';
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	DragMoveEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';

import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import {
	BreakoutRoom,
	EPermissions,
	ModuleGroupingTypes,
	ModuleGroups,
	PageModuleGroupModules,
	Session,
	SessionPanelLayoutsTypes,
	SessionTypesEnum,
	TranslateString,
} from "../../../../../../types/working-model";
import {
	setActiveSessionGroupModule, showEditSessionDetailsModal,
	toggleCoursesEnabled,
	updateSession,
	updateWorkingSession,
} from "../../../../../../store/actions/admin/create-event/session";
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import BasicCard from '../../../../../general-ui/basic-card/basic-card';
import Switch from '../../../../../general-ui/switch/switch';
import WaitingIndicator from '../../../../../general-ui/waiting-indicator/waiting-indicator';
import { toMap, updateTranslateKey, userHasRoleOnActiveChannel } from '../../../../../../utils/utils';
import { ParamsProps } from '../../../../../live-event/live-event';
import useTranslationsV2 from '../../../shared/use-translations-v2';
import EditSingleBreakoutRoom from './edit-single-breakout-room';
import { useGetAdminUrl } from '../../../../../../utils/admin-routing-utils';
import { SessionPanelContext, setActiveTabModule } from '../../../session/navigation/panel/session-panel-state';
import AddTabButton from './session-module-grouping-components/add-tab-button';
import NewModuleGroupCard from './session-module-grouping-components/new-module-group-card';
import { PageModuleGroup } from '../../../session/navigation/panel/utils/prototypes/page-module-group-modules';
import { latinChars } from '../../../../../../i18n/transliteration';
import { SessionPanelMap } from '../../../session/navigation/panel/session-panel-route-map';
import { GetDefaultTranslateString } from '../../../../../../store/utils/create-event';
import { getDefaultLanguage } from '../../../../../live-event/utils';
import TabTitle from './tab-title-input';
import { OptionalComponent } from 'utils/optional-component';

import './session-modules-lists-v2.scss';

interface ISortableTopLevelSessionModuleProps {
	moduleGroup: PageModuleGroupModules;
	workingSession: Session;
	id: string;
	index?: number;
	navigateTo: (tab: SessionPanelLayoutsTypes, customPath?: string) => void;
	deleteCustom: (uuid: string) => void;
	nonSorting: boolean;
}

const SortableTopLevelSessionModule: React.FC<ISortableTopLevelSessionModuleProps> = ({
	id,
	moduleGroup,
	workingSession,
	index,
	navigateTo,
	deleteCustom,
	nonSorting
}) => {
	const { dispatch: sessionPanelDispatch } = useContext(SessionPanelContext);

	const selectedPreviewGroup = useTypedSelector(state => state.CreateSessionReducer.selectedPreviewGroupUuid);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const { language } = useParams<ParamsProps>();
	const dispatch = useDispatch();
	const useV2 = useTranslationsV2();
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	const handleToggleTopLevelSwitch = async (
		selectedModuleGroup: PageModuleGroupModules,
		on: boolean
	) => {
		const updatedSession: Session = {
			...workingSession,
			module_grouping: workingSession?.module_grouping?.map(moduleGroup => {
				if (selectedModuleGroup.uuid !== moduleGroup.uuid) return moduleGroup;
				return {
					...moduleGroup,
					is_on: on,
				};
			}),
		};

		// if current selected preview group is this group, or if selected preview group is null and this module group is the first
		if (((selectedPreviewGroup === moduleGroup.uuid) || (!selectedPreviewGroup && index === 0)) && !on) {
			const firstAvailableSessionGroup = updatedSession.module_grouping?.find(group => group.is_on && group.uuid)?.uuid;
			if (firstAvailableSessionGroup) {
				// if toggling off, open up the next session group in the list
				dispatch(setActiveSessionGroupModule(firstAvailableSessionGroup));
			}
		}

		batch(() => {
			// update db
			if (token) {
				dispatch(updateSession(updatedSession, token));
			}
			// update redux
			dispatch(updateWorkingSession(updatedSession));
		});
	};

	const [name, setName] = useState('');
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		let _name: string;
		if (initialized) return;
		// This was previously static text, leaving a safety check for backwards compatibility
		//Check to see if "name" is a Translate string, if so return the translated value, if not return the static text
		const stringName = moduleGroup?.name as ModuleGroups;
		const translateName = moduleGroup?.name as TranslateString;
		if (typeof translateName === 'object' && workingSession.session_type !== SessionTypesEnum.fireside) {
			// if this is using V2, then we just show the tab title in the base language
			// fallback is for sessions created with a default language that was not one of the session languages (before that got fixed)
			_name = useV2 ? translateName.base : (translateName?.[language] as string ?? translateName.base ?? Object.values(translateName)[0]);
		} else if (typeof translateName === 'object' && workingSession.session_type === SessionTypesEnum.fireside) {
			// force fireside tabs to show in english so that the admin console is consistently english
			_name = (translateName['en'] ?? translateName?.[language] ?? translateName.base) as string;
		} else {
			_name = stringName;
		}
		setName(_name);
		setInitialized(true);
	}, [initialized, language, moduleGroup, name, useV2, workingSession]);

	const openOverviewModal = () => {
		dispatch(showEditSessionDetailsModal(true));
	};

	const handleCardClick = async () => {
		switch (moduleGroup.type) {
			case ModuleGroupingTypes.Engage:
				navigateTo(SessionPanelLayoutsTypes.Engage);
				sessionPanelDispatch(setActiveTabModule(moduleGroup));
				break;
			case ModuleGroupingTypes.Breakouts:
				break;
			case ModuleGroupingTypes.Details:
				console.log("Not implemented");
				// navigateTo(SessionPanelLayoutsTypes.Details);
				// sessionPanelDispatch(setActiveTabModule(moduleGroup));
				break;
			case ModuleGroupingTypes.Extras:
				navigateTo(SessionPanelLayoutsTypes.Extras);
				sessionPanelDispatch(setActiveTabModule(moduleGroup));
				break;
			case ModuleGroupingTypes.Education:
				navigateTo(SessionPanelLayoutsTypes.Education);
				sessionPanelDispatch(setActiveTabModule(moduleGroup));
				break;
			case ModuleGroupingTypes.custom:
				navigateTo(SessionPanelLayoutsTypes.Custom, (moduleGroup.name as TranslateString).base);
				sessionPanelDispatch(setActiveTabModule(moduleGroup));
				break;
			case ModuleGroupingTypes.Overview:
				break;

			default:
				break;
		}
	};

	const getBaseLanguage = useCallback(() => workingEvent && getDefaultLanguage(workingEvent), [workingEvent]);

	const handleNameBlur = () => {
		// update module name
		const baseLanguage = getBaseLanguage();
		if (!token) return;
		if (!baseLanguage) return;

		if (!workingSession.module_grouping) return;

		const updatedSession: Session = {
			...workingSession,
			module_grouping: workingSession.module_grouping.map((module: PageModuleGroupModules) => {
				if (moduleGroup.uuid !== module.uuid) return module;
				return {
					...module,
					name: updateTranslateKey({
						translateString: moduleGroup.name || GetDefaultTranslateString(),
						input: name,
						baseLanguage,
						language
					}),
				};
			}),
		};
		batch(() => {
			dispatch(updateWorkingSession(updatedSession));
			dispatch(updateSession(updatedSession, token));
		});
	};

	const _attributes = nonSorting ? {} : attributes;
	const _listeners = nonSorting ? {} : listeners;

	return (
		<div
			ref={setNodeRef}
			style={style}
		>
			<BasicCard p={0}>
				<div className="top-level-card" onClick={handleCardClick}>
					<div className="top-level-card-content">
						<div className="title">
							<div className={classNames("drag-handle", { "non-sortable": nonSorting })} {..._attributes} {..._listeners} >
								<Icon name={ICONS.DRAG_HANDLE} size={12} color={COLORS.GRAY} />
							</div>
							<TabTitle
								name={name}
								onChange={setName}
								onSubmit={handleNameBlur}
							/>
						</div>
						<div className="content-actions">
							{(() => {
								switch (moduleGroup.type) {
									case ModuleGroupingTypes.Overview: {
										return (
											<button onClick={openOverviewModal}>
												<Icon name={ICONS.EDIT} size={16} color={COLORS.LIGHT_GRAY} />
											</button>
										);
									}

									case ModuleGroupingTypes.custom: {
										return (
											<>
												<button
													onClick={(e) => {
														e.stopPropagation();
														deleteCustom(moduleGroup.uuid);
													}}
													className="top-level-card-delete-button"
												>
													<Icon name={ICONS.TRASH_OUTLINE} size={16} color={COLORS.LIGHT_GRAY} />
												</button>

												<Switch
													value={name}
													onClick={(_, on) => handleToggleTopLevelSwitch(moduleGroup, on)}
													on={moduleGroup.is_on}
													stopPropagation={true}
												/>
											</>
										);
									}

									default: {
										return (
											<Switch
												value={name}
												onClick={(_, on) => handleToggleTopLevelSwitch(moduleGroup, on)}
												on={moduleGroup.is_on}
												stopPropagation={true}
											/>
										);
									}
								}
							})()}
						</div>
					</div>


					{moduleGroup.type !== ModuleGroupingTypes.Overview && (
						<button
							className="no-style read-only-exception override-read-only-button"
							onClick={() => {
								// this is V1 behavior - removing this for V2 because it's causing over-rendering
								// scrollToEditor();
							}}
						>
							<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} color={COLORS.WHITE} size={16} />
						</button>
					)}

				</div>
			</BasicCard>
		</div>
	);
};

function SessionModuleGroupingListV2(): JSX.Element | null {
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const [shouldCloseAllLists, setShouldCloseAllLists] = useState(false);
	const [editingBreakout, setEditingBreakout] = useState<BreakoutRoom | null>(null);
	const [addingNewTab, setAddingNewTab] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { dispatch: sessionPanelDispatch } = useContext(SessionPanelContext);
	const adminPath = useGetAdminUrl();

	const currentChannel = useMemo(() => channels.find(channel => channel.channel === user?.active_channel), [channels]);
	const coursesFeatureEnabled = useMemo(() => currentChannel?.enabled_features?.includes('channel_feature.courses') || false, [currentChannel]);

	const sensors = useSensors(
		useSensor(PointerSensor),
	);

	const handleTopLevelDragEnd = ({ active, over }: DragEndEvent) => {
		setShouldCloseAllLists(false);
		if (validateUuid(active.id.toString())) {
			if (workingSession?.module_grouping) {
				const _modules = [...workingSession.module_grouping];
				const startIndex = _modules.findIndex(
					(module: PageModuleGroupModules) => module.uuid === active.id
				);
				const newIndex = _modules.findIndex(
					(module: PageModuleGroupModules) => module.uuid === over?.id
				);

				if (startIndex !== newIndex) {
					const [module] = _modules.splice(startIndex, 1);
					_modules.splice(newIndex, 0, module);
					const updatedSession: Session = {
						...workingSession,
						module_grouping: _modules,
					};
					batch(() => {
						// update db
						if (token) {
							dispatch(updateSession(updatedSession, token));
						}
						// update redux
						dispatch(updateWorkingSession(updatedSession));
					});
				}
			}

		} else if (workingSession?.module_grouping) {
			const isBreakouts = active.id.toString().startsWith('breakout');
			if (isBreakouts) {
				const activeId = Number(active.id.toString().replace('breakout-', ''));
				const overId = Number(over?.id.toString().replace('breakout-', ''));
				const startIndex = workingSession.breakout_room_order?.findIndex(num => num === activeId);
				const newIndex = workingSession.breakout_room_order?.findIndex(num => num === overId);
				if (typeof startIndex === 'number' && typeof newIndex === 'number') {
					const _breakout_room_order = [...(workingSession.breakout_room_order ?? [])];
					const [breakoutSplice] = _breakout_room_order.splice(startIndex, 1);
					_breakout_room_order.splice(newIndex, 0, breakoutSplice);

					const breakoutMap = toMap('id', workingSession.breakout_rooms ?? []);
					const updatedSession: Session = {
						...workingSession,
						module_grouping: workingSession?.module_grouping.map((module: PageModuleGroupModules) => {
							const isBreakout = module.type === 'breakout';
							if (isBreakout) {
								module.modules = [..._breakout_room_order];
							}

							return module;
						}),
						breakout_room_order: _breakout_room_order,
						breakout_rooms: _breakout_room_order.map(id => breakoutMap.get(id) as BreakoutRoom).filter(breakout => !!breakout)
					};

					batch(() => {
						// update db
						if (token) {
							dispatch(updateSession(updatedSession, token));
						}
						// update redux
						dispatch(updateWorkingSession(updatedSession));
					});
				}
			} else {

				// else is nested sortable modules:
				const activeId = Number(active.id);
				const overId = Number(over?.id);
				// first find the module grouping that the activeId belongs to
				// then do the splicing
				// then update the workingSession locally
				// then save in db and redux
				let hasChangedPosition = true;

				const updatedSession: Session = {
					...workingSession,
					module_grouping: workingSession?.module_grouping.map((module: PageModuleGroupModules) => {
						const matched = module.modules.find((moduleId: number) => moduleId === Number(activeId));
						if (!matched) return module;
						const _modules = [...module.modules];
						const startIndex = _modules.findIndex((module: number) => module === activeId);
						const newIndex = _modules.findIndex((module: number) => module === overId);
						if (startIndex === newIndex) {
							hasChangedPosition = false;
							return module;
						}
						const [moduleSplice] = _modules.splice(startIndex, 1);
						_modules.splice(newIndex, 0, moduleSplice);
						return {
							...module,
							modules: _modules,
						};
					}),
				};

				if (hasChangedPosition) {
					batch(() => {
						// update db
						if (token) {
							dispatch(updateSession(updatedSession, token));
						}
						// update redux
						dispatch(updateWorkingSession(updatedSession));
					});
				}
			}
		}
	};

	// if top level card is moved, we should close all lists, so it works properly
	const handleDragMove = (event: DragMoveEvent) => {
		if (validateUuid(event.active.id.toString())) {
			if (!shouldCloseAllLists) {
				setShouldCloseAllLists(true);
			}
		}
	};

	const closeEditBreakout = useCallback(() => {
		setEditingBreakout(null);
	}, []);

	const handleDeleteCustom = (uuid: string) => {
		if (!token || !workingSession) return;

		const groupToDelete = workingSession.module_grouping?.find(group => group.uuid === uuid);

		// we are removing the group from the session
		const updatedSession: Session = {
			...workingSession,
			module_grouping: workingSession?.module_grouping?.filter(moduleGroup => moduleGroup.uuid !== uuid),
			modules: workingSession?.modules?.filter(pageModule => !groupToDelete?.modules.includes(pageModule.id as number)),
		};

		batch(() => {
			dispatch(updateWorkingSession(updatedSession));
			dispatch(updateSession(updatedSession, token));
		});
	};

	if (!workingSession) return null;
	if (!workingSession?.module_grouping) return <WaitingIndicator />;

	return (
		<div className="session-module-grouping-list-container">
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleTopLevelDragEnd}
				modifiers={[restrictToParentElement]}
				onDragMove={handleDragMove}
				autoScroll={false}
			>
				<SortableContext
					items={workingSession?.module_grouping?.map((module: PageModuleGroupModules) => module.uuid)}
					strategy={verticalListSortingStrategy}
				>
					<>
						{workingSession.module_grouping.map((moduleGroup: PageModuleGroupModules, index: number) => (
							<SortableTopLevelSessionModule
								key={moduleGroup.uuid}
								id={moduleGroup.uuid}
								workingSession={workingSession}
								moduleGroup={moduleGroup}
								index={index}
								deleteCustom={handleDeleteCustom}
								nonSorting={moduleGroup.type === ModuleGroupingTypes.Overview}
								navigateTo={async (route: SessionPanelLayoutsTypes, customPath?: string) => {
									const path = customPath ? await latinChars.slugify(customPath) : undefined;

									history.push(
										adminPath({
											path: SessionPanelMap[route],
											customPath: path
										}),
										{
											panelTitle: moduleGroup.name
										}
									);
								}}
							/>	
						))}
						<OptionalComponent display={coursesFeatureEnabled && workingEvent?.settings.course_enabled}>
							<div>
								<BasicCard p={0}>
									<div className="top-level-card" onClick={() => {
										// history.push(
										// 	adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Courses] }),
										// 	{ panelTitle: 'Courses' }
										// );
										sessionPanelDispatch(setActiveTabModule({
											uuid: '',
											name: ModuleGroups.Courses,
											is_on: workingSession?.courses_settings?.enabled || false,
											modules: [],
											type: 'courses'
										}));
									}}>
										<div className="top-level-card-content">
											<div className="title">
												<div className={classNames("drag-handle", { "non-sortable": true })}>
													<Icon name={ICONS.DRAG_HANDLE} size={12} color={COLORS.GRAY} />
												</div>
												<TabTitle
													name="Courses"
													onChange={() => null}
													onSubmit={() => null}
												/>
											</div>
											<div className="content-actions">
												<Switch
													value="courses"
													onClick={(_, on) => dispatch(toggleCoursesEnabled(on))}
													on={workingSession?.courses_settings?.enabled || false}
													stopPropagation
													disabled={!userHasRoleOnActiveChannel([EPermissions.Owner, EPermissions.Admin, EPermissions.GroupAdmin, EPermissions.Builder, EPermissions.Editor], user)}
												/>
											</div>
										</div>

										<button className="no-style read-only-exception override-read-only-button" style={{ visibility: 'hidden' }}>
											<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} color={COLORS.WHITE} size={16} />
										</button>
									</div>
								</BasicCard>
							</div>
						</OptionalComponent>
					</>
				</SortableContext>
			</DndContext>

			{addingNewTab && (
				<NewModuleGroupCard
					onDone={async (e: React.ChangeEvent<HTMLInputElement>) => {
						if (e.target.value) {
							if (!token) return;

							const newGroup = new PageModuleGroup({
								name: e.target.value,
								currentLanguage: workingSession.default_language
							});

							await newGroup.ready();

							const updatedSession: Session = {
								...workingSession,
								module_grouping: [...(workingSession.module_grouping ?? []), newGroup],
							};

							dispatch(updateWorkingSession(updatedSession));
							dispatch(updateSession(updatedSession, token));
							setAddingNewTab(false);
						}
					}}
				/>
			)}

			<AddTabButton
				onClick={() => setAddingNewTab(true)}
			/>

			{!!workingSession.breakout_rooms && (
				<EditSingleBreakoutRoom
					open={!!editingBreakout}
					close={closeEditBreakout}
					room={editingBreakout}
					initialRooms={workingSession.breakout_rooms}
				/>
			)}
		</div>
	);
}

export default SessionModuleGroupingListV2;
