import { GetVideoArchive } from 'connection/content-connection';
import { DeleteRecordedVideos, DeleteUploadedVideos, GetChannelVideos, GetPendingDownloadRequests, GetRecordedVideos, UpdateVideoName } from '../../../connection/videos';
import { AsyncAction, Action } from '../../../types/actions';
import { ArchivedVideo, DownloadRecordedRequest, HomepageVideo } from '../../../types/working-model';
import { UploadJob } from '../../reducers/admin/videos';

export const GET_CHANNEL_VIDEOS = "GET_CHANNEL_VIDEOS";
export function getChannelUpVideos(token: string, channel: number): AsyncAction {
	return {
		type: GET_CHANNEL_VIDEOS,
		promise: GetChannelVideos(token, channel)
	};
}

export const VIDEO_CONVERSION_UPDATE = "VIDEO_CONVERSION_UPDATE";
export function videoConversionUpdate(video: HomepageVideo): Action {
	return {
		type: VIDEO_CONVERSION_UPDATE,
		payload: video
	};
}

export const CREATE_UPLOAD_JOB = "CREATE_UPLOAD_JOB";
export function createUploadJob(callback: (e: any) => void, onStarted?: (e: any) => void): Action {
	return {
		type: CREATE_UPLOAD_JOB,
		payload: { callback, onStarted }
	};
}

export const UPDATE_UPLOAD_JOB = "UPDATE_UPLOAD_JOB";
export function updateUploadJob(job: UploadJob): Action {
	return {
		type: UPDATE_UPLOAD_JOB,
		payload: job
	};
}

export const FINISH_UPLOAD_JOB = "FINISH_UPLOAD_JOB";
export function finishUploadJob(uuid: string): Action {
	return {
		type: FINISH_UPLOAD_JOB,
		payload: uuid
	};
}

export const GET_CHANNEL_RECORDINGS = "_GET_CHANNEL_RECORDINGS";
export function getChannelRecordings(token: string, channel: number): AsyncAction {
	return {
		type: GET_CHANNEL_RECORDINGS,
		promise: GetRecordedVideos(token, channel)
	};
}

export const GET_CHANNEL_ARCHIVED_VIDEOS = "GET_CHANNEL_ARCHIVED_VIDEOS";
export function getChannelArchivedVideos(token: string, channel: number, search: string, offset: number, limit: number, sortBy: string): AsyncAction {
	return {
		type: GET_CHANNEL_ARCHIVED_VIDEOS,
		promise: GetVideoArchive(token, channel, search, offset, limit, sortBy)
	};
}

export const UPDATE_CHANNEL_RECORDING = "UPDATE_CHANNEL_RECORDING";
export function updateChannelRecording(recording: HomepageVideo): Action {
	return {
		type: UPDATE_CHANNEL_RECORDING,
		payload: recording
	};
}

export const DELETE_RECORDED_VIDEOS = 'DELETE_RECORDED_VIDEOS';
export function deleteRecordedVideo(videoUuids: string[], token: string, channel: number): AsyncAction {
	return {
		type: DELETE_RECORDED_VIDEOS,
		promise: DeleteRecordedVideos(videoUuids, token, channel)
	};
}

export const DELETE_UPLOADED_VIDEOS = 'DELETE_UPLOADED_VIDEOS';
export function deleteUploadedVideo(videoUuids: string[], token: string, channel: number): AsyncAction {
	return {
		type: DELETE_UPLOADED_VIDEOS,
		promise: DeleteUploadedVideos(videoUuids, token, channel)
	};
}

export const CLEAR_DELETE_VIDEO_ERROR = "CLEAR_DELETE_VIDEO_ERROR";
export function clearDeleteVideoError(): Action {
	return {
		type: CLEAR_DELETE_VIDEO_ERROR,
		payload: ""
	};
}

export const CREATE_DOWNLOAD_REQUEST = "CREATE_DOWNLOAD_REQUEST";
export function createDownloadRequest(data: DownloadRecordedRequest): Action {
	return {
		type: CREATE_DOWNLOAD_REQUEST,
		payload: data
	};
}

export const GET_PENDING_DOWNLOADS = "GET_PENDING_DOWNLOADS";
export function getPendingDownloads(token: string, channel: number): AsyncAction {
	return {
		type: GET_PENDING_DOWNLOADS,
		promise: GetPendingDownloadRequests(token, channel)
	};
}

export const SET_DOWNLOAD_READY = "SET_DOWNLOAD_READY";
export function setDownloadReady(data: DownloadRecordedRequest): Action {
	return {
		type: SET_DOWNLOAD_READY,
		payload: data
	};
}

export const SET_DOWNLOAD_PROGRESS = "SET_DOWNLOAD_PROGRESS";
export function setDownloadProgress(data: DownloadRecordedRequest): Action {
	return {
		type: SET_DOWNLOAD_PROGRESS,
		payload: data
	};
}

export const SET_DOWNLOAD_ERROR = "SET_DOWNLOAD_ERROR";
export function setDownloadError(data: DownloadRecordedRequest): Action {
	return {
		type: SET_DOWNLOAD_ERROR,
		payload: data
	};
}

export const RENAME_VIDEO = "RENAME_VIDEO";
export const renameVideo = (token: string, videoId: number, newName: string): AsyncAction => {
	return {
		type: RENAME_VIDEO,
		promise: UpdateVideoName(token, videoId, newName)
	};
};

export const TOGGLE_SHOW_VIDEO_CONTROLS = "TOGGLE_SHOW_VIDEO_CONTROLS";
export function toggleShowVideoControls(showControls: boolean): Action {
	return {
		type: TOGGLE_SHOW_VIDEO_CONTROLS,
		payload: showControls
	};
}
