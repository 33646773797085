import { handle } from 'redux-pack';

import { Action } from "../../../types/actions";
import {
	BLChannelProfile,
	BlProfile,
	BlProfileSearchResult,
	BrandliveEvent,
	BreakoutRoom,
	Dictionary,
	PrivacyPolicy,
	RegistrationTypes,
	Session,
	TermsConditions,
	RegistrationStepType,
	IMultiSelectOption,
	QuizzesStatus,
	OptInStatus,
	ChannelFeaturesEnum,
	RegFieldsEnum,
	SessionPlaybackVideo,
} from '../../../types/working-model';
import { jwtDecode, sessionVideosWithCleanPlayback } from '../../../utils/utils';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../../utils/local-storage';
import { GET_BREAKOUT_ROOMS_FOR_SESSION, GET_BREAKOUT_ROOMS_FOR_SESSION_HV, GET_GOOGLE_MEET_ROOM } from '../../actions/event/breakout-rooms';
import {
	COMPLETE_PROFILE,
	GET_USER_SESSION,
	GET_PAGE_SESSION,
	REGISTER,
	SET_BL_USER,
	SET_REGISTRATION_STEP,
	SET_USER_SESSION,
	SET_PAGE_SESSION,
	TOGGLE_REGISTRATION_MODAL,
	SET_BL_PROFILE_TOKEN,
	UPDATE_EVENT_BUNDLE,
	CREATE_STRIPE_INTENT,
	FINISHED_STRIPE_PAYMENT,
	SET_PAID_SESSIONS,
	LOAD_PAGE,
	LOAD_EVENT_BUNDLE,
	GET_TICKET_INFO,
	PURCHASE_EVENTBRITE_TICKET,
	RE_REGISTER,
	SET_LIVE_SESSIONS,
	SET_LIVE_SESSIONS_SYNC,
	EDIT_PROFILE,
	RESET_REGISTRATION_STEP,
	SSO_REGISTER,
	SEARCH_PROFILES,
	BOOKMARK_PROFILE,
	DISPLAY_PROFILE_MODAL,
	DISPLAY_LOADING_PROFILE_MODAL,
	UPDATE_TEST_SESSION_STREAM,
	UPDATE_MY_LIST_SESSIONS,
	RE_REGISTER_CHECK,
	REGISTRATION_SIGN_IN,
	TOGGLE_ATTENDEE_IN_PERSON_MODE,
	UPDATE_VALID_SESSIONS,
	FORCE_COMPONENT_ERROR,
	SET_ERROR_BOUNDARY_COMPONENTS,
	CLEAR_ERROR_BOUNDARY_COMPONENTS,
	SEARCH_LEADERBOARD,
	GET_LEADERBOARD_DATA_FOR_PROFILE,
	SET_LEADERBOARD_IS_ON,
	GET_SESSION_USER_SURVEY_RATING,
	SET_SESSION_USER_SURVEY_RATING,
	SET_TRANSLATION_UPDATES,
	UPDATE_SESSION_CUSTOM_URL,
	CHECK_QUIZZES_FOR_COMPLETION,
	RESET_USER_SESSION,
	UPDATE_LOADING_VALID_SESSIONS,
	LOAD_LEADERBOARD_PROFILE_PAGINATED,
	CLEAR_LEADERBOARD_DATA_FOR_PROFILE,
	GET_MARKETING_EMAIL_SELECTION,
	FORCE_RECHECK_COOKIE_STATUS,
	CLEAR_REGISTRATION_ERROR,
	TOGGLE_GOOGLE_MEET_EMBED_VIEW,
	SET_LIVE_EVENT_SESSION_PLAYER_TYPE,
	SET_SHOW_SESSION_USER_FEEDBACK_SURVEY_MODAL,
	ACKNOWLEDGE_POPUP_ANNOUNCEMENT_V2,
	SET_LIVE_STATES,
	LIVE_STATE_CHANGE,
	CLEAR_ERROR_UPDATING_PROFILE,
	SET_EVENT_NAV_OPEN,
	SET_EVENT_BUNDLE,
	SET_VIEWING_SESSION_UUID,
	SET_REGISTRATION_ERROR,
	REGISTRATION_PASSWORD_RESET,
	UPDATE_REGISTRATION_PASSWORD,
	ADD_SESSION_VIDEOS_REPLAY,
	ADD_SESSION_VIDEOS_LIVE,
	DELETE_SESSION_VIDEOS_LIVE,
	SET_PLAYER_LATENCY_IN_SECONDS
} from '../../actions/event/event-actions';
import { LeaderboardEntry, LeaderboardScoreForProfile } from '../../../types/leaderboard';
import { getLeaderboardActionsAggregatedByAction, playbackUrlToPathname } from '../../utils/event-reducer-utils';
import { IRegistrationGDPR } from '../../../connection/registration';
import { SET_FIRESIDE_GOOGLE_MEET_SETTINGS } from 'store/actions/event/firesides-actions';
import { LiveState, LiveStateChangePayload, LiveStateEntry, LiveStateGetResponse, StreamState } from 'types/live-state';
import { RegFailureReasons } from 'utils/use-get-registration-error-message';
import Item from '@admin/create/homepage/editor/accordion/accordion-item/item';

export interface LiveEventState {
	eventBundle: BrandliveEvent | null;
	eventBundleUpdateCounter: number;
	playbackUrls: Record<string, string>;
	secondaryVideos: Record<string, string>;
	loadingEventBundle: boolean;
	eventBundleError: string;
	blProfileUserToken: string | undefined;
	blProfileUser: BlProfile | null;
	registrationId: string | null;
	registering: boolean;
	hasSubmittedRegistrationForm: boolean;
	validating: boolean;
	validationFailure: boolean;
	registrationError: string;
	validSessions: string[];
	validPasscodeLists: number[];
	loadingValidSessions: boolean;
	shouldAwaitValidation: boolean;
	userSession: string;
	pageSession: string;
	gettingUserSession: boolean;
	gettingPageSession: boolean;
	registrationStep: number;
	finishedRegistering: boolean;
	completingProfile: boolean;
	showRegistrationModal: string | null;
	userSesssion: string | null;
	getUserSessionError: string | null;
	getPageSessionError: string | null;
	termsConditions: TermsConditions | null;
	privacyPolicy: PrivacyPolicy | null;
	stripeIntent: any;
	stripeIntentError: any;
	_404: boolean;
	verificationCode: string | null;
	shouldAwaitVerification: boolean;
	verifyingUser: boolean;
	userVerified: boolean;
	verifyingFailed: boolean;
	shouldRedirectToRegistration: boolean;
	registrationDeleted: boolean;
	shouldRedirectToAlreadyRegisteredEmail?: string;
	numberOfTicketsPurchased: { [key: string]: number } | null;
	paymentRequired: boolean;
	paidSessions?: string[];
	liveSessions: string[];
	profiles: BlProfileSearchResult[];
	fetchingProfiles: boolean;
	displayProfileModal: BLChannelProfile | null;
	displayLoadingProfileModal: boolean;
	firesidesHostSessions: number[];
	recaptchaActionRequired: boolean;
	loginRecaptchaActionRequired: boolean;
	userSavedSessions: string[] | null;
	userSavedSessionsTemp: string[] | null;
	authorizedPages: number[];
	hasIncompleteRegistrationStep: boolean;
	errorBoundaries: {
		components: IMultiSelectOption[]
		componentsWithErrors: { [key: string]: boolean }
	};
	translationUpdates: number;
	registeredLanguage?: string;
	leaderboard: {
		individualsProfileLeaderboardData: LeaderboardScoreForProfile[] | null; // For profile page leaderboard section
		loading: boolean;
		error: boolean;
		attendees: LeaderboardEntry[];
		totalResults: number;
		initialPage: number;
	};
	leaderboardIsOn: boolean;
	sessionFeedbackSurveyRatings: { [key: string]: number }; // { [session_uuid]: rating }
	fetchingUserSurveyRating: boolean;
	quizzesStatus: Record<number, QuizzesStatus>;
	marketingEmailSelection: OptInStatus;
	gdpr?: IRegistrationGDPR;
	forceRecheckCookieStatus: number;
	embeddedMeetJoined: string | null; //need an app state to detect if the live user is currently in a google meet breakout room
	registration_bypass?: boolean;
	playerType?: string;
	showSessionUserFeedbackSurveyModal: string | null;
	acknowledgedPopupAnnouncements: number[];
	liveStates: LiveState;
	errorUpdatingProfile: boolean;
	navOpen: boolean;
	eventbriteOrderDetails: Record<string, string> | null;
	eventbriteOrderDetailsRetry: boolean
	eventbriteOrderDetailsRetryCount: number;
	viewingSessionUuid: string | null;
	registrationSignInSuccess?: boolean;
	playerLatencyInSeconds?: number;
}

const storedToken = getStorageItem('bl-profile');
const queryParams = new URLSearchParams(window.location.search);
const verification = queryParams.get('code');

const initialState: LiveEventState = {
	_404: false,
	authorizedPages: [],
	blProfileUser: storedToken ? jwtDecode(storedToken) as BlProfile : null,
	blProfileUserToken: storedToken,
	completingProfile: false,
	displayLoadingProfileModal: false,
	displayProfileModal: null,
	embeddedMeetJoined: null,
	errorBoundaries: {
		components: [],
		componentsWithErrors: {}
	},
	eventBundle: null,
	eventBundleError: "",
	eventBundleUpdateCounter: 1,
	eventbriteOrderDetails: null,
	eventbriteOrderDetailsRetry: false,
	eventbriteOrderDetailsRetryCount: 0,
	fetchingProfiles: false,
	finishedRegistering: false,
	firesidesHostSessions: [],
	forceRecheckCookieStatus: 0,
	gdpr: {},
	getPageSessionError: null,
	gettingPageSession: false,
	gettingUserSession: false,
	getUserSessionError: null,
	hasIncompleteRegistrationStep: false,
	hasSubmittedRegistrationForm: false,
	leaderboard: {
		attendees: [],
		error: false,
		individualsProfileLeaderboardData: null,
		initialPage: -1,
		loading: false,
		totalResults: 0,
	},
	leaderboardIsOn: false,
	liveSessions: [],
	loadingEventBundle: false,
	loadingValidSessions: true,
	loginRecaptchaActionRequired: false,
	marketingEmailSelection: OptInStatus.optedOut,
	numberOfTicketsPurchased: null,
	pageSession: '',
	paymentRequired: false,
	playbackUrls: {},
	playerType: undefined,
	privacyPolicy: null,
	profiles: [],
	quizzesStatus: {},
	recaptchaActionRequired: false,
	registering: false,
	registrationError: '',
	registrationId: null,
	registrationStep: 0,
	secondaryVideos: {},
	sessionFeedbackSurveyRatings: {},
	fetchingUserSurveyRating: true,
	shouldAwaitValidation: true,
	shouldAwaitVerification: !!verification,
	shouldRedirectToRegistration: false,
	registrationDeleted: false,
	showRegistrationModal: null,
	stripeIntent: null,
	stripeIntentError: null,
	termsConditions: null,
	translationUpdates: 0,
	userSavedSessions: null,
	userSavedSessionsTemp: null,
	userSession: '',
	userSesssion: null,
	userVerified: false,
	validating: false,
	validationFailure: false,
	validPasscodeLists: [],
	validSessions: [],
	verificationCode: verification,
	verifyingFailed: false,
	verifyingUser: false,
	showSessionUserFeedbackSurveyModal: null,
	acknowledgedPopupAnnouncements: [],
	liveStates: {},
	errorUpdatingProfile: false,
	navOpen: false,
	viewingSessionUuid: null,
	playerLatencyInSeconds: undefined
};

const completeRegistration = (eventUuid: string): void => {
	const regToken = getStorageItem(`incompleteRegistration.${eventUuid}`);
	removeStorageItem(`incompleteRegistration.${eventUuid}`);
	if (regToken) {
		setStorageItem(`reg.${eventUuid}`, regToken);
	}
};

const LiveEventReducer = (state = initialState, action: Action): LiveEventState => {
	const { type } = action;
	switch (type) {
		case SET_EVENT_BUNDLE: {
			if (!state.eventBundle) {
				return state;
			}

			return {
				...state,
				eventBundle: action.payload,
			};
		}
		case SET_TRANSLATION_UPDATES: {
			return ({ ...state, translationUpdates: action.payload });
		}
		case LOAD_PAGE: {
			return handle(state, action, {
				finish: state => ({ ...state, loadingEventBundle: false, userSavedSessions: action.payload.userSavedSessions }),
				success: state => ({ ...state, ...action.payload })
			});
		}
		case LOAD_EVENT_BUNDLE: {
			return handle(state, action, {
				start: state => ({ ...state, loadingEventBundle: true }),
				success: state => ({ ...state, ...action.payload })
			});
		}
		case SET_LIVE_STATES: {
			return handle(state, action, {
				success: state => {
					const response: LiveStateGetResponse = action.payload;

					const updates = response.reduce((acc, cur) => {
						const fixedUrl = playbackUrlToPathname(cur.playbackUrl);

						if (acc[fixedUrl] && acc[fixedUrl].state === 'live') {
							return acc;
						}

						return {
							...acc,
							[fixedUrl]: {
								state: cur.data.state,
								liveAt: cur.data.timestamp
							}
						};
					}, {} as { [key: string]: { state: StreamState, liveAt: number } });

					const newLiveStates = {
						...state.liveStates,
						...updates
					};

					// if these are the same by value return the old state so we don't do a useless re-render
					// which has a tendency to interrupt the live video stream
					if (JSON.stringify(newLiveStates) === JSON.stringify(state.liveStates)) {
						return state;
					}

					return { ...state, liveStates: newLiveStates };
				}
			});
		}
		case LIVE_STATE_CHANGE: {
			const payload: LiveStateChangePayload = action.payload;
			const _playbackUrl = action.payload.playbackUrl.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '');
			const playbackUrls = { ...state.playbackUrls };
			const event = state.eventBundle;
			const sessionVideos = event ? sessionVideosWithCleanPlayback([...event.sessionVideos ?? []]) : undefined;

			let updatePlayback = false;
			let updateSessionVideos = false;

			// if the live state has changed to live but the user does not yet know about the playback url
			// add it to the list of playback urls
			if (
				!playbackUrls[`${action.payload.session_uuid}-${action.payload.language}`]
				&& action.payload.state === 'live'
			) {
				playbackUrls[`${action.payload.session_uuid}-${action.payload.language}`] = _playbackUrl;
				updatePlayback = true;
			}

			// if the live state has changed but the user does not yet know about the session video
			// add it to the list of session videos
			if (
				sessionVideos && !sessionVideos.some(video =>
					video.session_uuid === action.payload.session_uuid
					&& video.language === action.payload.language
				)
			) {
				const payload: SessionPlaybackVideo = {
					session_uuid: action.payload.session_uuid,
					playback_url: _playbackUrl,
					language: action.payload.language,
					type: 'live_stream'
				};

				sessionVideos.push(payload);
				updateSessionVideos = true;
			}

			const newLiveStates: LiveState = {
				...state.liveStates,
				[playbackUrlToPathname(_playbackUrl)]: {
					state: payload.state,
					liveAt: Date.now(),
					endAt: payload.endAt
				}
			};

			return {
				...state,
				liveStates: newLiveStates,

				// if state changed to live and we didn't know about this URL, add it in
				playbackUrls: updatePlayback ? playbackUrls : state.playbackUrls,
				eventBundle: (updateSessionVideos && event) ? { ...event, sessionVideos } : state.eventBundle
			};
		}
		case GET_USER_SESSION: {
			return handle(state, action, {
				success: state => {
					setStorageItem(`ses.${action.payload.session}`, action.payload.uuid, 0.25); // 15 minutes
					return ({
						...state,
						userSesssion: action.payload.uuid,
						getUserSessionError: action.payload.getUserSessionError,
						gettingUserSession: false,
					});
				},
				start: state => ({ ...state, gettingUserSession: true, getUserSessionError: null }),
				finish: state => ({ ...state, gettingUserSession: false })
			});
		}
		case SET_USER_SESSION: {
			return {
				...state,
				userSession: action.payload
			};
		}
		case RESET_USER_SESSION: {
			return {
				...state,
				userSession: ''
			};
		}
		case GET_PAGE_SESSION: {
			return handle(state, action, {
				success: state => {
					setStorageItem(`pageSession.${action.payload.event}`, action.payload.uuid, 5);
					return ({ ...state, pageSession: action.payload.uuid, getPageSessionError: action.payload.getPageSessionError });
				},
				start: state => ({ ...state, gettingPageSession: true, getPageSessionError: null }),
				finish: state => ({ ...state, gettingPageSession: false })
			});
		}
		case SET_PAGE_SESSION: {
			return {
				...state,
				pageSession: action.payload
			};
		}
		case SET_BL_USER: {
			return {
				...state,
				blProfileUser: action.payload,
			};
		}
		case PURCHASE_EVENTBRITE_TICKET: {
			return handle(state, action, {
				success: state => {
					const details = action.payload?.details;
					if (details.statusCode === 500) {
						// recall the eventbrite order details
						return {
							...state,
							eventbriteOrderDetailsRetry: true,
							eventbriteOrderDetailsRetryCount: state.eventbriteOrderDetailsRetryCount + 1
						};
					}
					return {
						...state,
						eventbriteOrderDetails: action.payload?.details,
						eventbriteOrderDetailsRetry: false,
						eventbriteOrderDetailsRetryCount: 0
					};
				}
			});
		}
		case CREATE_STRIPE_INTENT: {
			return handle(state, action, {
				success: state => {
					if ("message" in action.payload) {
						return {
							...state,
							stripeIntentError: action.payload.message,
						};
					}

					return {
						...state,
						stripeIntent: action.payload,
					};
				},
				failure: state => {
					return {
						...state,
						stripeIntentError: action.payload.message,
					};
				}
			});
		}
		case FINISHED_STRIPE_PAYMENT: {
			const stepsOn = state.eventBundle!.registration_steps!.filter(step => step.isOn);
			let finished = false;

			if (state.registrationStep + 1 === stepsOn.length) {
				finished = true;
				completeRegistration(state.eventBundle!.uuid);
			}

			return {
				...state,
				registrationStep: state.registrationStep + 1,
				finishedRegistering: finished,
				paymentRequired: false,
				shouldRedirectToRegistration: !finished,
				hasIncompleteRegistrationStep: !finished,
			};

		}
		case SET_PAID_SESSIONS: {
			return {
				...state,
				paidSessions: action.payload
			};
		}
		case REGISTER: {
			return handle(state, action, {
				start: state => ({
					...state,
					registering: true
				}),
				success: state => {
					if (!state.eventBundle) return state;
					if (!state.eventBundle.registration_steps) return state;
					// if recaptcha v3 fails, enforce v2 action
					if (action.payload.actionRequired) {
						return {
							...state,
							recaptchaActionRequired: true,
						};
					}

					if ('failed' in action.payload) {
						return {
							...state,
							registrationError: action.payload.failed
						};
					}

					if ('error' in action.payload) {
						return {
							...state,
							registrationError: action.payload.error
						};
					} else {

						//do not store a dead token
						if (action.payload.token) {
							setStorageItem('bl-profile', action.payload.token);

							// because chat requires potential non registered users
							// we use a different token for that, but if the event is registered,
							// we should use the registered token
							setStorageItem('Bl-Profile-Token', action.payload.token);
						}

						//do not try to delete a dead token
						const user = action.payload.token ? jwtDecode(action.payload.token) as BlProfile : null;

						const stepsOn = state.eventBundle.registration_steps!.filter(step => step.isOn);

						let finished = false;
						setStorageItem(`incompleteRegistration.${state.eventBundle.uuid}`, action.payload.registrationId);

						if (state.registrationStep + 1 === stepsOn.length) {
							finished = true;
							completeRegistration(state.eventBundle.uuid);
						}

						if (action.payload.userCode) {
							setStorageItem('user-code', action.payload.userCode);
						}

						if (user && action.meta?.submittedPassword) {
							// only used to determine if we should show the pw confirmation field next time
							// they attend, not for determining validity of the password
							setStorageItem('hide-pw-confirmation-field', 'true');
						}

						return {
							...state,
							registrationId: action.payload.registrationId,
							registeredLanguage: action.payload.registered_language,
							blProfileUserToken: action.payload.token ? action.payload.token : null,
							blProfileUser: user,
							eventBundle: {
								...state.eventBundle,
								sessionVideos: sessionVideosWithCleanPlayback(action.payload.sessionVideos ?? []),
								sessions: action.payload.eventBundle.sessions
							},
							registrationError: '',
							finishedRegistering: finished,
							registrationStep: state.registrationStep + 1,
							playbackUrls: action.payload.playbackUrls ?? {},
							secondaryVideos: action.payload.secondaryVideos ?? {},
							validSessions: action.payload.validSessions,
							shouldRedirectToRegistration: false,
							paymentRequired: action.payload.paymentRequired,
							userVerified: action.payload.userVerified,
							firesidesHostSessions: action.payload.firesidesHost ?? [],
							paidSessions: action.payload.paidSessions ?? [],
							recaptchaActionRequired: false,
							userSavedSessions: action.payload.userSavedSessions,
							authorizedPages: action.payload.authorizedPages ?? [],
							hasIncompleteRegistrationStep: !finished,
							hasSubmittedRegistrationForm: true,
							leaderboardIsOn: action.payload.leaderboardIsOn,
							gdpr: action.payload.gdpr,
						};
					}
				},
				failure: state => {
					console.error('failure', action);
					try {
						const error = typeof action.payload.error === 'string' 
							? action.payload.error
							: JSON.parse(action.payload.error).error;

						return {
							...state,
							registrationError: error as RegFailureReasons
						};
					} catch (e) {
						return {
							...state,
							registrationError: 'Unable to register, please try again.'
						};
					}
				},
				finish: state => ({
					...state,
					registering: false,
				})
			});
		}

		case SSO_REGISTER: {
			return handle(state, action, {
				start: state => ({
					...state,
					registering: true
				}),
				success: state => {
					if (!state.eventBundle) return state;

					if ('failed' in action.payload) {
						return {
							...state,
							registrationError: action.payload.failed
						};
					}

					if ('error' in action.payload) {
						return {
							...state,
							registrationError: action.payload.error
						};
					} else {
						setStorageItem(`incompleteRegistration.${state.eventBundle.uuid}`, action.payload.registrationId);

						//do not store a dead token
						if (action.payload.token) {
							setStorageItem('bl-profile', action.payload.token);

							// because chat requires potential non registered users
							// we use a different token for that, but if the event is registered,
							// we should use the registered token
							setStorageItem('Bl-Profile-Token', action.payload.token);
						}

						const stepsOn = state.eventBundle.registration_steps!.filter(step => step.isOn);

						let finished = false;

						if (state.registrationStep + 1 === stepsOn.length) {
							finished = true;
							completeRegistration(state.eventBundle.uuid);
						}

						//do not try to delete a dead token
						const user = action.payload.token ? jwtDecode(action.payload.token) as BlProfile : null;

						return {
							...state,
							registrationId: action.payload.registrationId,
							registeredLanguage: action.payload.registered_language,
							blProfileUserToken: action.payload.token ? action.payload.token : null,
							blProfileUser: user,
							eventBundle: {
								...state.eventBundle,
								sessions: action.payload.eventBundle.sessions
							},
							registrationError: '',
							finishedRegistering: finished,
							registrationStep: state.registrationStep + 1,
							playbackUrls: action.payload.playbackUrls ?? {},
							validSessions: action.payload.validSessions,
							shouldRedirectToRegistration: false,
							paymentRequired: action.payload.paymentRequired,
							hasIncompleteRegistrationStep: !finished,
						};
					}
				},
				failure: state => {
					console.error('failure', action);
					try {
						const error = JSON.parse(action.payload.error);
						return {
							...state,
							registrationError: error.error as RegFailureReasons
						};
					} catch (e) {
						return {
							...state,
							registrationError: 'Unable to register, please try again.'
						};
					}
				},
				finish: state => ({
					...state,
					registering: false,
				})
			});
		}

		case REGISTRATION_SIGN_IN:
		case UPDATE_REGISTRATION_PASSWORD: {
			return handle(state, action, {
				start: state => ({
					...state,
					registering: true
				}),
				success: state => {
					if (!state.eventBundle) return state;

					if ('error' in action.payload) {
						return {
							...state,
							registrationError: action.payload.error
						};
					} else {
						if (!action.payload.token) return state;
						setStorageItem('bl-profile', action.payload.token);
						setStorageItem('Bl-Profile-Token', action.payload.token);
						removeStorageItem(`incompleteRegistration.${state.eventBundle.uuid}`);
						setStorageItem(`reg.${state.eventBundle.uuid}`, action.payload.registrationId);

						// the sign in did not return a user code so this user must re-verify their email
						if (!action.payload.userCode) {
							removeStorageItem('user-code');
						} else {
							setStorageItem('user-code', action.payload.userCode);
						}

						if (action.payload.newRegistration) {
							const user = action.payload.newRegistration.token ? jwtDecode(action.payload.newRegistration.token) as BlProfile : null;
							completeRegistration(state.eventBundle.uuid);

							return {
								...state,
								registrationId: action.payload.newRegistration.registrationId,
								registeredLanguage: action.payload.newRegistration.registered_language,
								blProfileUserToken: action.payload.newRegistration.token ? action.payload.newRegistration.token : null,
								blProfileUser: user,
								eventBundle: {
									...state.eventBundle,
									sessionVideos: sessionVideosWithCleanPlayback(action.payload.newRegistration.sessionVideos ?? []),
									sessions: action.payload.newRegistration.eventBundle.sessions
								},
								registrationError: '',
								finishedRegistering: true,
								registrationStep: state.registrationStep + 1,
								playbackUrls: action.payload.newRegistration.playbackUrls ?? {},
								secondaryVideos: action.payload.newRegistration.secondaryVideos ?? {},
								validSessions: action.payload.newRegistration.validSessions,
								shouldRedirectToRegistration: false,
								paymentRequired: action.payload.newRegistration.paymentRequired,
								userVerified: action.payload.newRegistration.userVerified,
								firesidesHostSessions: action.payload.newRegistration.firesidesHost ?? [],
								paidSessions: action.payload.newRegistration.paidSessions ?? [],
								recaptchaActionRequired: false,
								userSavedSessions: action.payload.newRegistration.userSavedSessions,
								authorizedPages: action.payload.newRegistration.authorizedPages ?? [],
								hasIncompleteRegistrationStep: false, // TODO fill this up when we make signing in users fill in missing fields
								hasSubmittedRegistrationForm: true,
								leaderboardIsOn: action.payload.newRegistration.leaderboardIsOn,
								gdpr: action.payload.newRegistration.gdpr,
								registrationSignInSuccess: true
							};
						}

						return {
							...state,
							// Page will hard redirect and load the token from storage
							registrationSignInSuccess: true
						};
					}
				},
				failure: state => {
					console.error('failure', action.payload);
					try {
						return {
							...state,
							registrationError: action.payload.error as RegFailureReasons
						};
					} catch (e) {
						return {
							...state,
							registrationError: 'Unable to register, please try again.'
						};
					}
				},
				finish: state => ({
					...state,
					registering: false
				})
			});
		}

		case SET_EVENT_NAV_OPEN: {
			return {
				...state,
				navOpen: action.payload
			};
		}

		case RE_REGISTER: {
			return handle(state, action, {
				start: state => ({ ...state, registering: true, verifyingUser: true }),
				success: state => {
					// if recaptcha v3 fails, enforce v2 action
					if (action.payload.actionRequired) {
						return {
							...state,
							loginRecaptchaActionRequired: true,
							registering: false
						};
					}

					if (action.payload.passwordGatingSuccess) {
						return {
							...state,
							loginRecaptchaActionRequired: true,
							registering: false
						};
					}

					return {
						...state,
						loginRecaptchaActionRequired: false,
						registering: false
					};
				},
				finish: state => ({ ...state, registering: false }),
			});
		}
		case REGISTRATION_PASSWORD_RESET: {
			return handle(state, action, {
				start: state => ({ ...state, registering: true }),
				success: state => {
					return {
						...state,
					};
				},
				finish: state => ({ ...state, registering: false })
			});
		}
		case RE_REGISTER_CHECK: {
			return handle(state, action, {
				success: state => ({ ...state, userSavedSessions: action.payload.userSavedSessions, verifyingUser: false })
			});
		}
		case COMPLETE_PROFILE: {
			return handle(state, action, {
				start: state => ({ ...state, completingProfile: true }),
				finish: state => ({ ...state, completingProfile: false }),
				success: state => {
					if (!state.eventBundle) return state;
					if (!state.eventBundle.registration_steps) return state;

					setStorageItem('bl-profile', action.payload.token);
					setStorageItem('Bl-Profile-Token', action.payload.token);

					const stepsOn = state.eventBundle.registration_steps.filter(step => step.isOn);

					let finished = false;

					if (state.registrationStep + 1 === stepsOn.length) {
						finished = true;
						completeRegistration(state.eventBundle.uuid);
					}

					return {
						...state,
						blProfileUserToken: action.payload.token,
						blProfileUser: jwtDecode(action.payload.token) as BlProfile,
						registrationStep: state.registrationStep + 1,
						finishedRegistering: finished,
						shouldRedirectToRegistration: !finished,
						hasIncompleteRegistrationStep: !finished,
					};
				}
			});
		}
		case EDIT_PROFILE: {
			return handle(state, action, {
				start: state => ({ ...state, completingProfile: true, errorUpdatingProfile: false }),
				finish: state => ({ ...state, completingProfile: false }),
				failure: state => ({ ...state, errorUpdatingProfile: true }),
				success: state => {
					if (!state.eventBundle || action.payload.error) return state;

					setStorageItem('bl-profile', action.payload.token);
					setStorageItem('Bl-Profile-Token', action.payload.token);

					const decodedUser = jwtDecode(action.payload.token) as BlProfile;
					let enableInPersonAttendeeMode = state.eventBundle.settings.attendeeInPersonEnabled;
					if (
						decodedUser.profile?.[state.eventBundle.channel]
						&& RegFieldsEnum.attend_in_person in decodedUser.profile[state.eventBundle.channel]
					) {
						enableInPersonAttendeeMode = decodedUser.profile?.[state.eventBundle.channel]?.[RegFieldsEnum.attend_in_person] === 'Yes';
					}

					return {
						...state,
						blProfileUserToken: action.payload.token,
						blProfileUser: decodedUser,
						eventBundle: {
							...state.eventBundle,
							settings: {
								...state.eventBundle.settings,
								attendeeInPersonEnabled: enableInPersonAttendeeMode,
							},
						},
						errorUpdatingProfile: false,
					};
				}
			});
		}

		case SET_REGISTRATION_STEP: {
			const stepsOn = state?.eventBundle?.registration_steps!.filter(step => step.isOn).length || 1;
			return ({
				...state, registrationStep: action.payload, finishedRegistering: action.payload >= stepsOn
			});
		}
		case TOGGLE_REGISTRATION_MODAL: {
			return {
				...state,
				showRegistrationModal: action.payload,
			};
		}
		case SET_BL_PROFILE_TOKEN: {
			return {
				...state,
				blProfileUserToken: action.payload,
			};
		}
		case CLEAR_ERROR_UPDATING_PROFILE: {
			return {
				...state,
				errorUpdatingProfile: false,
			};
		}
		case UPDATE_EVENT_BUNDLE: {
			//get ids of sessions currently loaded in the bundle
			const availableSessionIds = state.eventBundle?.sessions?.map(session => session.session);

			// Keep existing feature flags; Bundle doesn't contian these anyway.
			const channelFeatures: ChannelFeaturesEnum[] =
				action.payload?.channel_features
				|| state.eventBundle?.channel_features
				|| [];

			// if no gating, just go ahead and add all sessions
			let updatedSessions: Session[] = [];

			const updatedEventBundle: BrandliveEvent = action.payload;

			const ticketing = updatedEventBundle?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing && step.isOn);

			// if no reg or (no gating and no ticketing), add all sessions
			if (
				!updatedEventBundle.registration_on
				|| (
					updatedEventBundle.registration_settings?.type === RegistrationTypes.open
					&& !ticketing
				)
			) {
				updatedSessions = updatedEventBundle.sessions;
			} else {
				// we can just set the sessions based on the current availableSessionIds (even though it's potentially stale session data)
				// because incrementing eventBundleUpdateCounter will force a re-run of the useCheckUserGating custom hook
				// which will refetch the users valid sessions properly
				updatedSessions = updatedEventBundle.sessions?.filter((session: Session) => availableSessionIds?.includes(session.session));
			}

			// do not overrite users setting
			let attendeeInPersonEnabled = !!state.eventBundle?.settings.attendeeInPersonEnabled;

			// however, if admin has toggled off the setting, set it to false
			if (!action.payload?.registration_settings?.adminInPersonAttendeeModeEnabled) {
				attendeeInPersonEnabled = false;
			}

			return {
				...state,
				eventBundleUpdateCounter: state.eventBundleUpdateCounter + 1,
				eventBundle: {
					...action.payload,
					channel_features: channelFeatures,
					settings: {
						...(action.payload.settings ?? {}),
						attendeeInPersonEnabled, // do not overrite users setting
					},
					sessions: updatedSessions,
				},
			};
		}
		case GET_TICKET_INFO: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						numberOfTicketsPurchased: action.payload,
					};
				},
				failure: state => {
					return {
						...state,
					};
				}
			});
		}
		case SET_LIVE_SESSIONS: {
			return handle(state, action, {
				success: state => ({ ...state, liveSessions: action.payload })
			});
		}
		case SET_LIVE_SESSIONS_SYNC: {
			return ({ ...state, liveSessions: action.payload });
		}
		case RESET_REGISTRATION_STEP: {
			return ({ ...state, registrationStep: action.payload });
		}
		case GET_BREAKOUT_ROOMS_FOR_SESSION: {
			return handle(state, action, {
				success: state => {
					const breakoutRooms: BreakoutRoom[] = action.payload;
					const session = state.eventBundle?.sessions.find(_session => _session.session === breakoutRooms[0].session);
					const eventBundle = state.eventBundle;

					if (!session || !eventBundle) {
						return state;
					}

					session.breakout_rooms = breakoutRooms;

					for (let i = 0; i < eventBundle.sessions.length; i++) {
						if (eventBundle.sessions[i].session === session.session) {
							eventBundle.sessions[i] = session;

							return ({ ...state, eventBundle: eventBundle });
						}
					}

					return state;
				}
			});
		}
		case GET_BREAKOUT_ROOMS_FOR_SESSION_HV: {
			return handle(state, action, {
				success: state => {
					const breakoutRooms: BreakoutRoom[] = action.payload?.breakout_rooms;
					const session = state.eventBundle?.sessions.find(_session => _session.session === breakoutRooms?.[0]?.session);
					const eventBundle = state.eventBundle;

					if (!session || !eventBundle) {
						return state;
					}

					session.breakout_rooms = breakoutRooms;

					for (let i = 0; i < eventBundle.sessions.length; i++) {
						if (eventBundle.sessions[i].session === session.session) {
							eventBundle.sessions[i] = session;

							//spreading eventBundle so redux recognizes event bundle as a new object and rerenders
							return ({ ...state, eventBundle: { ...eventBundle } });
						}
					}

					return state;
				}
			});
		}
		case GET_GOOGLE_MEET_ROOM: {
			return handle(state, action, {
				success: state => {
					const breakoutRoom: BreakoutRoom = action.payload.breakoutRoom;
					const session = state.eventBundle?.sessions.find(_session => _session.session === breakoutRoom.session);
					const eventBundle = state.eventBundle;

					if (!session || !eventBundle || !session.breakout_rooms) {
						return state;
					}

					// Replace breakout room on session
					for (let i = 0; i < session.breakout_rooms.length; i++) {
						if (session.breakout_rooms[i].id === breakoutRoom.id) {
							session.breakout_rooms[i] = breakoutRoom;
						}
					}

					for (let i = 0; i < eventBundle.sessions.length; i++) {
						if (eventBundle.sessions[i].session === session.session) {
							eventBundle.sessions[i] = session;

							//spreading eventBundle so redux recognizes event bundle as a new object and rerenders
							return ({ ...state, eventBundle: { ...eventBundle } });
						}
					}

					return state;
				}
			});
		}
		case SET_FIRESIDE_GOOGLE_MEET_SETTINGS: {
			const updatedSessions = state.eventBundle?.sessions.map(session => {
				if (session.uuid === action.payload.sessionUuid) {
					session.fireside_session_settings = action.payload;
				}

				return session;
			});

			if (updatedSessions && state.eventBundle) {
				return {
					...state,
					eventBundle: {
						...state.eventBundle,
						sessions: updatedSessions
					}
				};
			}

			return state;
		}
		case SEARCH_PROFILES: {
			return handle(state, action, {
				start: state => {
					if (!state.eventBundle) return state;
					return {
						...state,
						fetchingProfiles: true
					};
				},
				success: state => {
					if (!state.eventBundle) return state;

					return {
						...state,
						profiles: action.payload
					};
				},
				finish: state => {
					if (!state.eventBundle) return state;

					return {
						...state,
						fetchingProfiles: false
					};
				}
			});
		}
		case BOOKMARK_PROFILE: {
			return handle(state, action, {
				success: state => {
					if (!state.eventBundle) return state;

					setStorageItem('bl-profile', action.payload.token);
					setStorageItem('Bl-Profile-Token', action.payload.token);

					return {
						...state,
						blProfileUserToken: action.payload.token,
						blProfileUser: jwtDecode(action.payload.token) as BlProfile,
					};
				}
			});
		}

		case DISPLAY_LOADING_PROFILE_MODAL: {
			return {
				...state,
				displayLoadingProfileModal: action.payload,
			};
		}

		case DISPLAY_PROFILE_MODAL: {
			return {
				...state,
				displayProfileModal: action.payload,
				displayLoadingProfileModal: false,
			};
		}

		case UPDATE_TEST_SESSION_STREAM: {
			const { isTest, sessionUuid } = action.payload;
			const session = state.eventBundle?.sessions.find(s => s.uuid === sessionUuid);
			const newEventBundle = state.eventBundle;
			if (!session || !newEventBundle) {
				return state;
			}

			for (const s of newEventBundle.sessions) {
				if (s.session === session.session) {
					s.streaming_options = { ...session.streaming_options, test_stream: isTest };

					return { ...state, eventBundle: { ...newEventBundle } };
				}
			}

			return {
				...state,
				eventBundle: { ...newEventBundle }
			};
		}
		case UPDATE_MY_LIST_SESSIONS: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						userSavedSessionsTemp: action.meta?.payload ?? null
					};
				},
				success: state => {
					return {
						...state,
						userSavedSessions: action.payload.saved_sessions
					};
				},
				finish: state => {
					return {
						...state,
						userSavedSessionsTemp: null
					};
				}
			});
		}
		case TOGGLE_ATTENDEE_IN_PERSON_MODE: {
			if (!state.eventBundle?.settings) return state;

			return {
				...state,
				eventBundle: {
					...state.eventBundle,
					settings: {
						...state.eventBundle.settings,
						attendeeInPersonEnabled: action.payload.isEnabled
					}
				}
			};
		}

		case UPDATE_VALID_SESSIONS: {
			if (
				!action.payload?.validSessions
				|| !action.payload?.sessions
				|| !state.eventBundle
			) {
				return {
					...state,
					loadingValidSessions: false,
				};
			}
			return {
				...state,
				eventBundle: {
					...state.eventBundle,
					sessions: action.payload.sessions
				},
				validSessions: action.payload.validSessions,
				validPasscodeLists: action.payload.validPasscodeLists ?? [],
				loadingValidSessions: false,
				registration_bypass: !!action.payload.registration_bypass,
			};
		}

		case UPDATE_LOADING_VALID_SESSIONS: {
			return {
				...state,
				loadingValidSessions: action.payload
			};
		}

		case SET_ERROR_BOUNDARY_COMPONENTS: {
			return {
				...state,
				errorBoundaries: {
					...state.errorBoundaries,
					components: [...state.errorBoundaries.components, action.payload]
				}
			};
		}


		case FORCE_COMPONENT_ERROR: {
			return {
				...state,
				errorBoundaries: {
					...state.errorBoundaries,
					componentsWithErrors: {
						...state.errorBoundaries.componentsWithErrors,
						[action.payload]: true
					}
				}
			};
		}

		case CLEAR_ERROR_BOUNDARY_COMPONENTS: {
			return {
				...state,
				errorBoundaries: {
					components: [],
					componentsWithErrors: {}
				}
			};
		}

		case UPDATE_SESSION_CUSTOM_URL: {
			if (!state.eventBundle) {
				return state;
			} else {
				const { sessionUuid, enabled, customUrl } = action.payload;

				const newSessions = [...state.eventBundle.sessions];

				newSessions.forEach((session: Session) => {
					if (session.uuid === sessionUuid) {
						session.custom_url_enabled = enabled;
						session.custom_url = customUrl;
					}
				});

				return {
					...state,
					eventBundle: {
						...state.eventBundle,
						sessions: newSessions
					}
				};
			}
		}

		case GET_SESSION_USER_SURVEY_RATING: {
			if (!state.eventBundle || !action.payload) {
				return state;
			}
			return handle(state, action, {
				start: state => {
					return {
						...state,
						fetchingUserSurveyRating: true,
					};
				},
				success: state => {
					const { session_uuid, rating } = action.payload;

					return {
						...state,
						fetchingUserSurveyRating: false,
						sessionFeedbackSurveyRatings: {
							...state.sessionFeedbackSurveyRatings,
							[session_uuid]: rating || 0
						}
					};
				},
				finish: state => {
					return {
						...state,
						fetchingUserSurveyRating: false,
					};
				},
			});
		}

		case SET_SESSION_USER_SURVEY_RATING: {
			if (!state.eventBundle || !action.payload) {
				return state;
			} else {
				const { sessionUuid, rating } = action.payload;

				return {
					...state,
					sessionFeedbackSurveyRatings: {
						...state.sessionFeedbackSurveyRatings,
						[sessionUuid]: rating
					}
				};
			}
		}

		case CHECK_QUIZZES_FOR_COMPLETION: {
			return {
				...state,
				quizzesStatus: action.payload
			};
		}

		case SEARCH_LEADERBOARD: {
			if (!state.eventBundle) return state;

			return handle(state, action, {
				start: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: true }
					};
				},
				success: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, error: false, attendees: action.payload.results, totalResults: action.payload.total, initialPage: -1 }
					};
				},
				failure: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, error: true }
					};
				},
				finish: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: false }
					};
				}
			});
		}

		case LOAD_LEADERBOARD_PROFILE_PAGINATED: {
			if (!state.eventBundle) return state;

			return handle(state, action, {
				start: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: true }
					};
				},
				success: state => {
					const results = action.payload.results;

					return {
						...state,
						leaderboard: {
							...state.leaderboard,
							error: false,
							attendees: results,
							totalResults: action.payload.total,
							// sets the page based on the rank of the last person in the results. i.e. ceil(10/9) = 2, ceil(18/9) = 2
							initialPage: Math.ceil(results[results.length - 1].rank / 9)
						}
					};
				},
				failure: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, error: true }
					};
				},
				finish: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: false }
					};
				}
			});
		}

		case GET_LEADERBOARD_DATA_FOR_PROFILE: {
			if (!state.eventBundle) return state;

			return handle(state, action, {
				start: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: true }
					};
				},
				success: state => {
					const collatedActions = getLeaderboardActionsAggregatedByAction(action.payload);

					return {
						...state,
						leaderboard: {
							...state.leaderboard,
							individualsProfileLeaderboardData: collatedActions
						}
					};
				},
				finish: state => {
					return {
						...state,
						leaderboard: { ...state.leaderboard, loading: false }
					};
				}
			});
		}

		case CLEAR_LEADERBOARD_DATA_FOR_PROFILE: {
			return {
				...state,
				leaderboard: {
					...state.leaderboard,
					individualsProfileLeaderboardData: null,
				}
			};
		}

		case SET_LEADERBOARD_IS_ON: {
			return {
				...state,
				leaderboardIsOn: action.payload
			};
		}

		case GET_MARKETING_EMAIL_SELECTION: {
			return {
				...state,
				marketingEmailSelection: action.payload?.status
			};
		}

		case FORCE_RECHECK_COOKIE_STATUS: {
			return {
				...state,
				forceRecheckCookieStatus: state.forceRecheckCookieStatus + 1,
			};
		}

		case CLEAR_REGISTRATION_ERROR: {
			return {
				...state,
				registrationError: '',
			};
		}

		case SET_REGISTRATION_ERROR: {
			return {
				...state,
				registrationError: action.payload
			};
		}

		case TOGGLE_GOOGLE_MEET_EMBED_VIEW: {
			return {
				...state,
				embeddedMeetJoined: action.payload
			};
		}

		case SET_LIVE_EVENT_SESSION_PLAYER_TYPE: {
			return {
				...state,
				playerType: action.payload
			};
		}

		case SET_SHOW_SESSION_USER_FEEDBACK_SURVEY_MODAL: {
			return {
				...state,
				showSessionUserFeedbackSurveyModal: action.payload
			};
		}

		case ACKNOWLEDGE_POPUP_ANNOUNCEMENT_V2: {
			return {
				...state,
				acknowledgedPopupAnnouncements: [...state.acknowledgedPopupAnnouncements, action.payload]
			};
		}

		case SET_VIEWING_SESSION_UUID: {
			return {
				...state,
				viewingSessionUuid: action.payload || null,
			};
		}

		case ADD_SESSION_VIDEOS_REPLAY: {
			if (!state.eventBundle) return state;
			const payload = {
				...action.payload,
				playback_url: action.payload.playback_url?.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '')
			};
			const replayEndpoints = { ...(state.eventBundle.secondaryVideos ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.eventBundle.sessionVideos ?? []);
			const eventBundle = { ...state.eventBundle };
			const replayExists = sessionVideos.some(video => {
				return (
					video.session_uuid === payload.session_uuid &&
					video.language === payload.language &&
					video.type === 'replay'
				);
			});

			if (replayExists) {
				eventBundle.sessionVideos = sessionVideos.map(video => {
					if (
						video.session_uuid === payload.session_uuid &&
						video.language === payload.language &&
						video.type === 'replay'
					) {
						return payload;
					}
					return video;
				});
			} else {
				eventBundle.sessionVideos = [...sessionVideos, payload];
			}

			replayEndpoints[`${payload.session_uuid}_${payload.language}`] = payload.playback_url;
			eventBundle.secondaryVideos = replayEndpoints;

			return {
				...state,
				eventBundle
			};
		}

		case ADD_SESSION_VIDEOS_LIVE: {
			if (!state.eventBundle) return state;
			const payload = {
				...action.payload,
				playback_url: action.payload.playback_url?.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '')
			};
			const playbackUrls = { ...(state.eventBundle.playbackUrls ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.eventBundle.sessionVideos ?? []);
			const eventBundle = { ...state.eventBundle };
			const liveVideoExists = sessionVideos.some(video => {
				return (
					video.session_uuid === payload.session_uuid &&
					video.language === payload.language &&
					video.type === 'live_stream'
				);
			});

			if (liveVideoExists) {
				eventBundle.sessionVideos = sessionVideos.map(video => {
					if (
						video.session_uuid === payload.session_uuid &&
						video.language === payload.language &&
						video.type === 'live_stream'
					) {
						return payload;
					}
					return video;
				});
			} else {
				eventBundle.sessionVideos = [...sessionVideos, payload];
			}

			playbackUrls[`${payload.session_uuid}_${payload.language}`] = payload.playback_url;
			eventBundle.playbackUrls = playbackUrls;
			return {
				...state,
				eventBundle
			};
		}

		case DELETE_SESSION_VIDEOS_LIVE: {
			if (!state.eventBundle) return state;

			const playbackUrls = { ...(state.eventBundle.playbackUrls ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.eventBundle.sessionVideos ?? []).filter(video => {
				if (
					video.session_uuid === action.payload.sessionUuid &&
					video.language === action.payload.language &&
					video.type === 'live_stream'
				) {
					return false;
				}

				return true;
			});

			delete playbackUrls[`${action.payload.sessionUuid}_${action.payload.language}`];

			const eventBundle = {
				...state.eventBundle,
				sessionVideos,
				playbackUrls
			};

			return {
				...state,
				eventBundle
			};
		}

		case SET_PLAYER_LATENCY_IN_SECONDS: {
			return {
				...state,
				playerLatencyInSeconds: action.payload
			};
		}

		default: return state;
	}
};

export default LiveEventReducer;