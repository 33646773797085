import { UpdateSessionInfo } from "../../../../connection/create-event";
import {
	GetSessionsForEvent,
	UpdateBreakoutBannerImage,
	UpdateUseBreakoutBackgroundImage,
	UpdateSecondarySessionVideo,
	GetSecondarySessionVideo,
	DeleteSecondarySessionVideo,
	UpdateBreakoutBackgroundImage
} from "../../../../connection/sessions";
import { DispatchedAction, DispatchedAsyncAction } from "../../../../types/actions";
import {
	EBroadcastTypes,
	CreateVideo,
	DBSession,
	HomepageVideo,
	PageModule,
	PageModuleGroupModules,
	PageModuleType,
	SecondarySessionVideo,
	Session,
	SessionLayout,
	SessionVideoCustomizations,
	SessionPanelLayoutsTypes,
	SessionTabNames,
	IBreakoutSession,
	SessionTabsChatStyles,
	SessionChatContentSettings
} from "../../../../types/working-model";

export const LOAD_WORKING_SESSION = "LOAD_WORKING_SESSION";
type loadWorkingSessionAction = DispatchedAction<typeof LOAD_WORKING_SESSION, Session | null>;
export function loadWorkingSession(session: Session | null): loadWorkingSessionAction {
	return {
		type: LOAD_WORKING_SESSION,
		payload: session
	};
}

export const CLEAR_WORKING_SESSION = "CLEAR_WORKING_SESSION";
type clearWorkingSessionAction = DispatchedAction<typeof CLEAR_WORKING_SESSION, null>;
export function clearWorkingSession(): clearWorkingSessionAction {
	return {
		type: CLEAR_WORKING_SESSION,
		payload: null
	};
}

export const TOGGLE_PAGE_MODULE = "TOGGLE_PAGE_MODULE";
type togglePageModuleAction = DispatchedAction<typeof TOGGLE_PAGE_MODULE, PageModule>;
export function togglePageModule(module: PageModule): togglePageModuleAction {
	return {
		type: TOGGLE_PAGE_MODULE,
		payload: module
	};
}

export const UPDATE_PAGE_MODULE = "UPDATE_PAGE_MODULE";
type updatePageModuleAction = DispatchedAction<typeof UPDATE_PAGE_MODULE, PageModule>;
export function updatePageModule(module: PageModule): updatePageModuleAction {
	return {
		type: UPDATE_PAGE_MODULE,
		payload: module
	};
}

export const UPDATE_PAGE_MODULE_AND_SAVE = "UPDATE_PAGE_MODULE_AND_SAVE";
type updatePageModuleAndSaveAction = DispatchedAction<typeof UPDATE_PAGE_MODULE_AND_SAVE, PageModule>;
export function updatePageModuleAndSave(module: PageModule): updatePageModuleAndSaveAction {
	return {
		type: UPDATE_PAGE_MODULE_AND_SAVE,
		payload: module
	};
}

export const SHOW_EDIT_SESSION_DETAILS_MODAL = "SHOW_EDIT_SESSION_DETAILS_MODAL";
type showEditSessionDetailsModalAction = DispatchedAction<typeof SHOW_EDIT_SESSION_DETAILS_MODAL, boolean>;
export function showEditSessionDetailsModal(openSessionDetailsEditModal: boolean): showEditSessionDetailsModalAction {
	return {
		type: SHOW_EDIT_SESSION_DETAILS_MODAL,
		payload: openSessionDetailsEditModal
	};
}

export const SET_SESSION_LAYOUT = "SET_SESSION_LAYOUT";
type setSessionLayoutAction = DispatchedAction<typeof SET_SESSION_LAYOUT, SessionLayout>;
export function setSessionLayout(options: SessionLayout): setSessionLayoutAction {
	return {
		type: SET_SESSION_LAYOUT,
		payload: options
	};
}

export const SET_SESSION_MODULES = "SET_SESSION_MODULES";
type setSessionModulesAction = DispatchedAction<typeof SET_SESSION_MODULES, PageModule[]>;
export function setSessionModules(modules: PageModule[]): setSessionModulesAction {
	return {
		type: SET_SESSION_MODULES,
		payload: modules
	};
}

export const ADD_SESSION_PAGE_MODULE = "ADD_SESSION_PAGE_MODULE";
type addSessionPageModuleAction = DispatchedAction<typeof ADD_SESSION_PAGE_MODULE, PageModule>;
export function addSessionPageModule(page_module: PageModule): addSessionPageModuleAction {
	return {
		type: ADD_SESSION_PAGE_MODULE,
		payload: page_module
	};
}

export const SET_SESSION_ACTIVE_MODULE = "SET_SESSION_ACTIVE_MODULE";
type setSessionActiveModuleAction = DispatchedAction<typeof SET_SESSION_ACTIVE_MODULE, PageModuleType>;
export function setSessionActiveModule(selected: PageModuleType): setSessionActiveModuleAction {
	return {
		type: SET_SESSION_ACTIVE_MODULE,
		payload: selected
	};
}

export const SET_SESSION_ACTIVE_MODULE_BY_ID = "SET_SESSION_ACTIVE_MODULE_BY_ID";
type setSessionActiveModuleByIdAction = DispatchedAction<typeof SET_SESSION_ACTIVE_MODULE_BY_ID, number | null>;
export function setSessionActiveModuleById(moduleId: number | null): setSessionActiveModuleByIdAction {
	return {
		type: SET_SESSION_ACTIVE_MODULE_BY_ID,
		payload: moduleId
	};
}

export const SET_ACTIVE_SESSION_GROUP_MODULE = "SET_ACTIVE_SESSION_GROUP_MODULE";
type setActiveSessionGroupModuleAction = DispatchedAction<typeof SET_ACTIVE_SESSION_GROUP_MODULE, string | null>;
export function setActiveSessionGroupModule(moduleGroupUuid: string | null): setActiveSessionGroupModuleAction {
	return {
		type: SET_ACTIVE_SESSION_GROUP_MODULE,
		payload: moduleGroupUuid
	};
}

export const SET_SESSION_USE_NAV = "SET_SESSION_USE_NAV";
type setSessionUseNavAction = DispatchedAction<typeof SET_SESSION_USE_NAV, boolean>;
export function setSessionUseNav(useNav: boolean): setSessionUseNavAction {
	return {
		type: SET_SESSION_USE_NAV,
		payload: useNav
	};
}

export const GET_SESSIONS_FOR_EVENT = "GET_SESSIONS_FOR_EVENT";
type getSessionsForEventAction = DispatchedAsyncAction<typeof GET_SESSIONS_FOR_EVENT, DBSession[]>;
export function getSessionsForEvent(token: string, uuid: string): getSessionsForEventAction {
	return {
		type: GET_SESSIONS_FOR_EVENT,
		promise: GetSessionsForEvent(token, uuid)
	};
}

export const UPDATE_SESSIONS_LIST_FOR_EVENT = "UPDATE_SESSIONS_LIST_FOR_EVENT";
type updateSessionsListForEventAction = DispatchedAction<typeof UPDATE_SESSIONS_LIST_FOR_EVENT, DBSession>;
export function updateSessionsListForEvent(session: DBSession): updateSessionsListForEventAction {
	return {
		type: UPDATE_SESSIONS_LIST_FOR_EVENT,
		payload: session
	};
}

export const UPDATE_SESSION = "UPDATE_SESSION";
type updateSessionAction = DispatchedAsyncAction<typeof UPDATE_SESSION, Session>;
export function updateSession(session: Session, token: string): updateSessionAction {
	return {
		type: UPDATE_SESSION,
		promise: UpdateSessionInfo(session, token),
	};
}

export const UPDATE_WORKING_SESSION = "UPDATE_WORKING_SESSION";
type updateWorkingSessionAction = DispatchedAction<typeof UPDATE_WORKING_SESSION, Session>;
export function updateWorkingSession(session: Session): updateWorkingSessionAction {
	return {
		type: UPDATE_WORKING_SESSION,
		payload: session
	};
}

export const UPDATING_SESSION = "UPDATING_SESSION";
type updatingSessionAction = DispatchedAction<typeof UPDATING_SESSION, boolean>;
export function updatingSession(isUpdating: boolean): updatingSessionAction {
	return {
		type: UPDATING_SESSION,
		payload: isUpdating
	};
}

export const UPDATE_MODULE_GROUPINGS = "UPDATE_MODULE_GROUPINGS";
type updateGroupModulesAction = DispatchedAction<typeof UPDATE_MODULE_GROUPINGS, PageModuleGroupModules[]>;
export function updateGroupModules(moduleGroupings: PageModuleGroupModules[]): updateGroupModulesAction {
	return {
		type: UPDATE_MODULE_GROUPINGS,
		payload: moduleGroupings
	};
}

export const UPDATE_SESSION_STYLING = "UPDATE_SESSION_STYLING";
type updateSessionStylingAction = DispatchedAction<typeof UPDATE_SESSION_STYLING, Session['layout']['styling_overrides']>;
export function updateSessionStyling(styling: Session['layout']['styling_overrides']): updateSessionStylingAction {
	return {
		type: UPDATE_SESSION_STYLING,
		payload: styling
	};
}

export const SET_ON_DEMAND_VIDEO = "SET_ON_DEMAND_VIDEO";
type setOnDemandVideoAction = DispatchedAction<typeof SET_ON_DEMAND_VIDEO, CreateVideo | HomepageVideo>;
export function setOnDemandVideo(video: CreateVideo | HomepageVideo): setOnDemandVideoAction {
	return {
		type: SET_ON_DEMAND_VIDEO,
		payload: video
	};
}

export const SET_SESSION_BROADCAST_TYPE = "SET_SESSION_BROADCAST_TYPE";
type setSessionBroadcastTypeAction = DispatchedAction<typeof SET_SESSION_BROADCAST_TYPE, EBroadcastTypes>;
export function setSessionBroadcastType(type: EBroadcastTypes): setSessionBroadcastTypeAction {
	return {
		type: SET_SESSION_BROADCAST_TYPE,
		payload: type
	};
}

export const SET_VIDEO_CUSTOMIZATIONS = "SET_VIDEO_CUSTOMIZATIONS";
type setVideoCustomizationsAction = DispatchedAction<typeof SET_VIDEO_CUSTOMIZATIONS, SessionVideoCustomizations>;
export function setVideoCustomizations(customizations: SessionVideoCustomizations): setVideoCustomizationsAction {
	return {
		type: SET_VIDEO_CUSTOMIZATIONS,
		payload: customizations
	};
}

export const UPDATE_MODERATION_SETTINGS = "UPDATE_MODERATION_SETTINGS";
type updateModerationSettingsAction = DispatchedAction<typeof UPDATE_MODERATION_SETTINGS, { posts: boolean, questions: boolean, chat: boolean }>;
export function updateModerationSettings(settings: { posts: boolean, questions: boolean, chat: boolean }): updateModerationSettingsAction {
	return {
		type: UPDATE_MODERATION_SETTINGS,
		payload: settings
	};
}

export const UPDATE_CHAT_CONTENT_SETTINGS = "UPDATE_CHAT_CONTENT_SETTINGS";
type updatChatContentSettingsAction = DispatchedAction<typeof UPDATE_CHAT_CONTENT_SETTINGS, SessionChatContentSettings>;
export function updatChatContentSettings(settings: SessionChatContentSettings): updatChatContentSettingsAction {
	return {
		type: UPDATE_CHAT_CONTENT_SETTINGS,
		payload: settings
	};
}

export const UPDATE_SHOULD_RECORD = "UPDATE_SHOULD_RECORD";
type updateShouldRecordAction = DispatchedAction<typeof UPDATE_SHOULD_RECORD, boolean>;
export function updateShouldRecord(on: boolean): updateShouldRecordAction {
	return {
		type: UPDATE_SHOULD_RECORD,
		payload: on
	};
}

export const TOGGLE_DISPLAY_VIEW_COUNT = "TOGGLE_DISPLAY_VIEW_COUNT";
type toggleDisplayViewCountAction = DispatchedAction<typeof TOGGLE_DISPLAY_VIEW_COUNT, boolean>;
export function toggleDisplayViewCount(isOn: boolean): toggleDisplayViewCountAction {
	return {
		type: TOGGLE_DISPLAY_VIEW_COUNT,
		payload: isOn
	};
}

export const SET_SESSION_CSS = "SET_SESSION_CSS";
type setSessionCssAction = DispatchedAction<typeof SET_SESSION_CSS, string>;
export function setSessionCss(css: string): setSessionCssAction {
	return {
		type: SET_SESSION_CSS,
		payload: css
	};
}

export const SET_SESSION_COMPILED_CSS = "SET_SESSION_COMPILED_CSS";
type setSessionCompiledCssAction = DispatchedAction<typeof SET_SESSION_COMPILED_CSS, string | undefined>;
export function setSessionCompiledCss(css?: string): setSessionCompiledCssAction {
	return {
		type: SET_SESSION_COMPILED_CSS,
		payload: css
	};
}

export const SET_SESSION_USE_SAFE_CSS = "SET_SESSION_USE_SAFE_CSS";
type setSessionUseSafeCssAction = DispatchedAction<typeof SET_SESSION_USE_SAFE_CSS, boolean>;
export function setSessionUseSafeCss(on: boolean): setSessionUseSafeCssAction {
	return {
		type: SET_SESSION_USE_SAFE_CSS,
		payload: on
	};
}

export const REMOVE_SESSION_FROM_CREATE_SESSION = 'REMOVE_SESSION_FROM_CREATE_SESSION';
type removeSessionFromCreateSessionReducerAction = DispatchedAction<typeof REMOVE_SESSION_FROM_CREATE_SESSION, { sessionID: number, eventUuid: string }>;
export function removeSessionFromCreateSessionReducer(sessionID: number, eventUuid: string): removeSessionFromCreateSessionReducerAction {
	return {
		type: REMOVE_SESSION_FROM_CREATE_SESSION,
		payload: { sessionID, eventUuid },
	};
}

export const UPDATE_BREAKOUT_BANNER_IMAGE = "UPDATE_BREAKOUT_BANNER_IMAGE";
type updateBreakoutBannerImageAction = DispatchedAsyncAction<typeof UPDATE_BREAKOUT_BANNER_IMAGE, IBreakoutSession>;
export function updateBreakoutBannerImage(image: string | null, sessionId: number, token: string): updateBreakoutBannerImageAction {
	return {
		type: UPDATE_BREAKOUT_BANNER_IMAGE,
		promise: UpdateBreakoutBannerImage(image, sessionId, token),
	};
}


export const UPDATE_USE_BREAKOUT_IMAGE = "UPDATE_USE_BREAKOUT_IMAGE";
type updateUseBreakoutImageAction = DispatchedAsyncAction<typeof UPDATE_USE_BREAKOUT_IMAGE, IBreakoutSession>;
export function updateUseBreakoutImage(updated: boolean, sessionId: number, token: string): updateUseBreakoutImageAction {
	return {
		type: UPDATE_USE_BREAKOUT_IMAGE,
		promise: UpdateUseBreakoutBackgroundImage(updated, sessionId, token),
	};
}

export const UPDATE_BREAKOUT_BACKGROUND_IMAGE = "UPDATE_BREAKOUT_BACKGROUND_IMAGE";
type updateBreakoutBackgroundImageAction = DispatchedAsyncAction<typeof UPDATE_BREAKOUT_BACKGROUND_IMAGE, IBreakoutSession>;
export function updateBreakoutBackgroundImage(image: string | null, sessionId: number, token: string): updateBreakoutBackgroundImageAction {
	return {
		type: UPDATE_BREAKOUT_BACKGROUND_IMAGE,
		promise: UpdateBreakoutBackgroundImage(image, sessionId, token),
	};
}

export const TOGGLE_REPLAY_ON = "TOGGLE_REPLAY_ON";
type toggleReplayOnAction = DispatchedAction<typeof TOGGLE_REPLAY_ON, boolean>;
export function toggleReplayOn(isOn: boolean): toggleReplayOnAction {
	return {
		type: TOGGLE_REPLAY_ON,
		payload: isOn
	};
}

export const TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON = "TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON";
type toggleAutoSendCalendarInviteOnAction = DispatchedAction<typeof TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON, boolean>;
export function toggleAutoSendCalendarInviteOn(isOn: boolean): toggleAutoSendCalendarInviteOnAction {
	return {
		type: TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON,
		payload: isOn
	};
}

export const TOGGLE_SESSION_FEEDBACK_SURVEY = "TOGGLE_SESSION_FEEDBACK_SURVEY";
type toggleSessionFeedbackSurveyAction = DispatchedAction<typeof TOGGLE_SESSION_FEEDBACK_SURVEY, boolean>;
export function toggleSessionFeedbackSurvey(isOn: boolean): toggleSessionFeedbackSurveyAction {
	return {
		type: TOGGLE_SESSION_FEEDBACK_SURVEY,
		payload: isOn
	};
}

export const TOGGLE_SESSION_SHARE = "TOGGLE_SESSION_SHARE";
type toggleSessionShareAction = DispatchedAction<typeof TOGGLE_SESSION_SHARE, boolean>;
export function toggleSessionShare(isOn: boolean): toggleSessionShareAction {
	return {
		type: TOGGLE_SESSION_SHARE,
		payload: isOn
	};
}

export const TOGGLE_REACTIONS = "TOGGLE_REACTIONS";
type toggleReactionsAction = DispatchedAction<typeof TOGGLE_REACTIONS, boolean>;
export function toggleReactions(enabled: boolean): toggleReactionsAction {
	return {
		type: TOGGLE_REACTIONS,
		payload: enabled
	};
}

export const UPDATE_SECONDARY_SESSION_VIDEO = "UPDATE_SECONDARY_SESSION_VIDEO";
type updateSecondarySessionVideoAction = DispatchedAsyncAction<typeof UPDATE_SECONDARY_SESSION_VIDEO, SecondarySessionVideo>;
export function updateSecondarySessionVideo(secondaryVideo: Omit<SecondarySessionVideo, "id" | "uuid">, token: string): updateSecondarySessionVideoAction {
	return {
		type: UPDATE_SECONDARY_SESSION_VIDEO,
		promise: UpdateSecondarySessionVideo(secondaryVideo, token)
	};
}

export const GET_SECONDARY_SESSION_VIDEO = "GET_SECONDARY_SESSION_VIDEO";
type getSecondarySessionVideoAction = DispatchedAsyncAction<typeof GET_SECONDARY_SESSION_VIDEO, SecondarySessionVideo | { response: string; }>;
export function getSecondarySessionVideo(sessionId: number, token: string): getSecondarySessionVideoAction {
	return {
		type: GET_SECONDARY_SESSION_VIDEO,
		promise: GetSecondarySessionVideo(sessionId, token)
	};
}

export const DELETE_SECONDARY_SESSION_VIDEO = "DELETE_SECONDARY_SESSION_VIDEO";
type deleteSecondarySessionVideoAction = DispatchedAsyncAction<typeof DELETE_SECONDARY_SESSION_VIDEO, string>;
export function deleteSecondarySessionVideo(sessionId: number, token: string): deleteSecondarySessionVideoAction {
	return {
		type: DELETE_SECONDARY_SESSION_VIDEO,
		promise: DeleteSecondarySessionVideo(sessionId, token)
	};
}

export const TOGGLE_DISPLAY_SESSION_CHAT = "TOGGLE_DISPLAY_SESSION_CHAT";
type toggleDisplaySessionChatAction = DispatchedAction<typeof TOGGLE_DISPLAY_SESSION_CHAT, boolean>;
export function toggleDisplaySessionChat(isOn: boolean): toggleDisplaySessionChatAction {
	return {
		type: TOGGLE_DISPLAY_SESSION_CHAT,
		payload: isOn
	};
}

export const SET_SESSION_CHAT_STYLE = "SET_SESSION_CHAT_STYLE";
type setSessionChatStyleAction = DispatchedAction<typeof SET_SESSION_CHAT_STYLE, SessionTabsChatStyles>;
export function setSessionChatStyle(styleType: SessionTabsChatStyles): setSessionChatStyleAction {
	return {
		type: SET_SESSION_CHAT_STYLE,
		payload: styleType
	};
}

export const SET_SESSION_CHAT_DARK_MODE = "SET_SESSION_CHAT_DARK_MODE";
type setSessionChatDarkModeAction = DispatchedAction<typeof SET_SESSION_CHAT_DARK_MODE, boolean>;
export function setSessionChatDarkMode(isOn: boolean): setSessionChatDarkModeAction {
	return {
		type: SET_SESSION_CHAT_DARK_MODE,
		payload: isOn
	};
}

export const RESET_WORKING_SESSION_CHAT_DARK_MODE = "RESET_WORKING_SESSION_CHAT_DARK_MODE";
type resetWorkingSessionChatDarkModeAction = DispatchedAction<typeof RESET_WORKING_SESSION_CHAT_DARK_MODE, undefined>;
export function resetWorkingSessionChatDarkMode(): resetWorkingSessionChatDarkModeAction {
	return {
		type: RESET_WORKING_SESSION_CHAT_DARK_MODE,
		payload: undefined
	};
}

export const SET_SESSION_INTERNAL_DESCRIPTOR = "SET_SESSION_INTERNAL_DESCRIPTOR";
type setSessionInternalDescriptorAction = DispatchedAction<typeof SET_SESSION_INTERNAL_DESCRIPTOR, string>;
export function setSessionInternalDescriptor(internalDescriptor: string): setSessionInternalDescriptorAction {
	return {
		type: SET_SESSION_INTERNAL_DESCRIPTOR,
		payload: internalDescriptor
	};
}

export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";
type setCurrentLanguageAction = DispatchedAction<typeof SET_CURRENT_LANGUAGE, string>;
export function setCurrentLanguage(language: string): setCurrentLanguageAction {
	return {
		type: SET_CURRENT_LANGUAGE,
		payload: language
	};
}

export const SET_SESSION_LOADING = "SET_SESSION_LOADING";
type setSessionLoadingAction = DispatchedAction<typeof SET_SESSION_LOADING, boolean>;
export function setSessionLoading(loading: boolean): setSessionLoadingAction {
	return {
		type: SET_SESSION_LOADING,
		payload: loading
	};
}

export const SET_SESSION_PANEL_LAYOUT_TYPE = "SET_SESSION_PANEL_LAYOUT_TYPE";
type setSessionPanelLayoutTypeAction = DispatchedAction<typeof SET_SESSION_PANEL_LAYOUT_TYPE, SessionPanelLayoutsTypes | SessionTabNames | null>;
export function setSessionPanelLayoutType(layoutType: SessionPanelLayoutsTypes | SessionTabNames | null): setSessionPanelLayoutTypeAction {
	return {
		type: SET_SESSION_PANEL_LAYOUT_TYPE,
		payload: layoutType
	};
}

export const UPDATE_SESSION_CALENDAR_SETTINGS = "UPDATE_SESSION_CALENDAR_SETTINGS";
type updateSessionCalendarSettingsAction = DispatchedAction<typeof UPDATE_SESSION_CALENDAR_SETTINGS, {}>;
export function updateSessionCalendarSettings(calendarSettings = {}): updateSessionCalendarSettingsAction {
	return {
		type: UPDATE_SESSION_CALENDAR_SETTINGS,
		payload: calendarSettings
	};
}

export const SAVE_WORKING_SESSION = "SAVE_WORKING_SESSION";
type saveWorkingSessionAction = DispatchedAction<typeof SAVE_WORKING_SESSION, undefined>;
export function saveWorkingSession(): saveWorkingSessionAction {
	return {
		type: SAVE_WORKING_SESSION,
		payload: undefined
	};
}

export const UPDATE_SESSION_CUSTOM_SOCIAL_LINK = "UPDATE_SESSION_CUSTOM_SOCIAL_LINK";
type updateSessionCustomSocialLinkAction = DispatchedAction<typeof UPDATE_SESSION_CUSTOM_SOCIAL_LINK, { linkType: string, link: string }>;
export function updateSessionCustomSocialLink(linkType: string, link: string): updateSessionCustomSocialLinkAction {
	return {
		type: UPDATE_SESSION_CUSTOM_SOCIAL_LINK,
		payload: { linkType, link }
	};
}

export const TOGGLE_COURSES_ENABLED = "TOGGLE_COURSES_ENABLED";
type toggleCoursesEnabledAction = DispatchedAction<typeof TOGGLE_COURSES_ENABLED, boolean>;
export function toggleCoursesEnabled(isOn: boolean): toggleCoursesEnabledAction {
	return {
		type: TOGGLE_COURSES_ENABLED,
		payload: isOn
	};
}

export const TOGGLE_VIDEO_WATERMARK = "TOGGLE_VIDEO_WATERMARK";
type toggleVideoWatermarkAction = DispatchedAction<typeof TOGGLE_VIDEO_WATERMARK, boolean>;
export function toggleVideoWatermark(isOn: boolean): toggleVideoWatermarkAction{
	return {
		type: TOGGLE_VIDEO_WATERMARK,
		payload: isOn
	};
}

export type CreateSessionActions =
	| loadWorkingSessionAction
	| clearWorkingSessionAction
	| togglePageModuleAction
	| updatePageModuleAction
	| updatePageModuleAndSaveAction
	| showEditSessionDetailsModalAction
	| setSessionLayoutAction
	| setSessionModulesAction
	| addSessionPageModuleAction
	| setSessionActiveModuleAction
	| setSessionActiveModuleByIdAction
	| setActiveSessionGroupModuleAction
	| setSessionUseNavAction
	| getSessionsForEventAction
	| updateSessionsListForEventAction
	| updateSessionAction
	| updateWorkingSessionAction
	| updatingSessionAction
	| updateGroupModulesAction
	| updateSessionStylingAction
	| setOnDemandVideoAction
	| setSessionBroadcastTypeAction
	| setVideoCustomizationsAction
	| updateModerationSettingsAction
	| updateShouldRecordAction
	| toggleDisplayViewCountAction
	| setSessionCssAction
	| setSessionCompiledCssAction
	| setSessionUseSafeCssAction
	| removeSessionFromCreateSessionReducerAction
	| updateBreakoutBannerImageAction
	| updateUseBreakoutImageAction
	| updateBreakoutBackgroundImageAction
	| toggleReplayOnAction
	| toggleAutoSendCalendarInviteOnAction
	| toggleSessionFeedbackSurveyAction
	| toggleSessionShareAction
	| toggleReactionsAction
	| updateSecondarySessionVideoAction
	| getSecondarySessionVideoAction
	| deleteSecondarySessionVideoAction
	| toggleDisplaySessionChatAction
	| setSessionChatStyleAction
	| setSessionChatDarkModeAction
	| resetWorkingSessionChatDarkModeAction
	| setSessionInternalDescriptorAction
	| setCurrentLanguageAction
	| setSessionLoadingAction
	| setSessionPanelLayoutTypeAction
	| updateSessionCalendarSettingsAction
	| saveWorkingSessionAction
	| updateSessionCustomSocialLinkAction
	| updatChatContentSettingsAction
	| toggleCoursesEnabledAction
	| toggleVideoWatermarkAction;
