import { handle } from 'redux-pack';
import { v4 as uuid } from 'uuid';

import { Action } from '../../../types/actions';
import { DownloadRecordedRequest, DownloadRecordedRequestStatus, HomepageVideo, IVSRecording, ArchivedVideo } from '../../../types/working-model';
import {
	CLEAR_DELETE_VIDEO_ERROR,
	CREATE_DOWNLOAD_REQUEST,
	CREATE_UPLOAD_JOB,
	DELETE_RECORDED_VIDEOS,
	DELETE_UPLOADED_VIDEOS,
	FINISH_UPLOAD_JOB,
	GET_CHANNEL_RECORDINGS,
	GET_CHANNEL_VIDEOS,
	GET_PENDING_DOWNLOADS,
	SET_DOWNLOAD_ERROR,
	SET_DOWNLOAD_PROGRESS,
	SET_DOWNLOAD_READY,
	UPDATE_CHANNEL_RECORDING,
	UPDATE_UPLOAD_JOB,
	VIDEO_CONVERSION_UPDATE,
	RENAME_VIDEO,
	TOGGLE_SHOW_VIDEO_CONTROLS,
	GET_CHANNEL_ARCHIVED_VIDEOS
} from '../../actions/admin/videos';
import { isHomepageVideo } from 'utils/is-homepage-video';

export interface UploadJob {
	fileSelected: boolean;
	uploading: boolean;
	onComplete: (e: any) => void;
	onStarted: (e: any) => void;
	uuid: string;
}

export interface VideosState {
	videos: HomepageVideo[];
	recordedVideos: (IVSRecording | HomepageVideo)[];
	archivedVideos: ArchivedVideo[];
	downloadRecordingRequests: DownloadRecordedRequest[];
	loadingVideos: boolean;
	errorLoadingVideos: boolean;
	errorLoadingArchivedVideos: boolean;
	uploadJobs: UploadJob[];
	loadingRecordings: boolean;
	loadingArchivedVideos: boolean;
	errorLoadingRecordings: boolean;
	deleteVideoError: string;
	showVideoControls: boolean;
	archivedVideoCount: number;
}

const initialState: VideosState = {
	deleteVideoError: '',
	downloadRecordingRequests: [],
	errorLoadingRecordings: false,
	errorLoadingVideos: false,
	errorLoadingArchivedVideos: false,
	loadingRecordings: false,
	loadingVideos: false,
	loadingArchivedVideos: false,
	recordedVideos: [],
	showVideoControls: false,
	uploadJobs: [],
	videos: [],
	archivedVideos: [],
	archivedVideoCount: 0
};

export default function VideosReducer(
	state: VideosState = initialState,
	action: Action
): VideosState {
	switch (action.type) {
		case GET_CHANNEL_VIDEOS: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingVideos: true }),
				failure: (state) => ({ ...state, errorLoadingVideos: true }),
				success: (state) => {
					return { ...state, videos: action.payload, errorLoadingVideos: false };
				},
				finish: (state) => ({ ...state, loadingVideos: false })
			});
		}
		case GET_CHANNEL_RECORDINGS: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingRecordings: true }),
				failure: (state) => ({ ...state, errorLoadingRecordings: true }),
				success: (state) => ({ ...state, recordedVideos: action.payload, errorLoadingRecordings: false }),
				finish: (state) => ({ ...state, loadingRecordings: false })
			});
		}
		case UPDATE_CHANNEL_RECORDING: {
			return {
				...state,
				recordedVideos: state.recordedVideos.map(video => {
					if (isHomepageVideo(video)) {
						return video.video === action.payload.video ? action.payload : video;
					} else {
						return video.id === action.payload.id ? action.payload : video;
					}
				})
			};
		}
		case GET_PENDING_DOWNLOADS: {
			return handle(state, action, {
				success: state => ({ ...state, downloadRecordingRequests: action.payload })
			});
		}
		case CREATE_DOWNLOAD_REQUEST: {
			return { ...state, downloadRecordingRequests: [...state.downloadRecordingRequests, action.payload] };
		}
		case SET_DOWNLOAD_PROGRESS: {
			return {
				...state,
				downloadRecordingRequests: state.downloadRecordingRequests.map(req => {
					if (req.id === action.payload.id) {
						req.progress = action.payload.progress;
					}

					return req;
				})
			};
		}
		case SET_DOWNLOAD_READY: {
			return {
				...state,
				downloadRecordingRequests: state.downloadRecordingRequests.map(req => {
					if (req.id === action.payload.id) {
						req.progress = 100;
						req.status = DownloadRecordedRequestStatus.completed;
					}

					return req;
				})
			};
		}
		case SET_DOWNLOAD_ERROR: {
			return {
				...state,
				downloadRecordingRequests: state.downloadRecordingRequests.map(req => {
					if (req.id === action.payload.id) {
						req.progress = 0;
						req.status = DownloadRecordedRequestStatus.failed;
					}

					return req;
				})
			};
		}
		case CREATE_UPLOAD_JOB: {
			return ({
				...state,
				uploadJobs: [
					...state.uploadJobs,
					{
						fileSelected: false,
						uploading: false,
						onComplete: action.payload.callback,
						onStarted: action.payload.onStarted,
						uuid: uuid()
					}
				]
			});
		}
		case UPDATE_UPLOAD_JOB: {
			return ({
				...state,
				uploadJobs: state.uploadJobs.map((job) => {
					if (job.uuid === action.payload.uuid) {
						return action.payload;
					} else {
						return job;
					}
				})
			});
		}
		case FINISH_UPLOAD_JOB: {
			return ({
				...state,
				uploadJobs: state.uploadJobs.filter((job) => job.uuid !== action.payload)
			});
		}
		case DELETE_RECORDED_VIDEOS: {
			return handle(state, action, {
				success: state => {
					const {
						videoIDs,
						cannotDelete
					} = action.payload as { videoIDs: string[]; cannotDelete?: string[] };
					const deletedSet = new Set(videoIDs);
					const cannotDeleteSet = new Set(cannotDelete || []);

					//filter out videos if they exist in the videosIDs coming back from the recently deleted list
					const _recordedVideos = videoIDs.length
						? state.recordedVideos.filter(video => !deletedSet.has(video.uuid))
						: state.recordedVideos;

					// give list of videos in use if they were deleted
					const errorMessage = cannotDelete?.length
						? state.recordedVideos
							.filter(video => cannotDeleteSet.has(video.uuid))
							.map(video => video.name)
							.join(", ")
						: "";

					return {
						...state,
						recordedVideos: _recordedVideos,
						deleteVideoError: errorMessage
					};
				},
			});
		}
		case DELETE_UPLOADED_VIDEOS: {
			return handle(state, action, {
				success: state => {
					const {
						videoIDs,
						cannotDelete
					} = action.payload as { videoIDs: string[]; cannotDelete?: string[] };
					const deletedSet = new Set(videoIDs);
					const cannotDeleteSet = new Set(cannotDelete || []);

					//filter out videos if they exist in the videosIDs coming back from the recently deleted list
					const _videos = videoIDs.length
						? state.videos.filter(video => !deletedSet.has(video.uuid))
						: state.videos;

					// give list of videos in use if they were deleted
					const errorMessage = cannotDelete?.length
						? state.videos
							.filter(video => cannotDeleteSet.has(video.uuid))
							.map(video => video.name)
							.join(", ")
						: "";

					return {
						...state,
						videos: _videos,
						deleteVideoError: errorMessage
					};
				},
			});
		}
		case CLEAR_DELETE_VIDEO_ERROR: {
			return {
				...state,
				deleteVideoError: ""
			};
		}
		case VIDEO_CONVERSION_UPDATE: {
			return {
				...state,
				videos: state.videos.map(video => video.video === action.payload.video ? action.payload : video)
			};
		}
		case RENAME_VIDEO: {
			return handle(state, action, {
				success: state => {
					const updatedVideoId = Number(action.payload.videoId);

					return {
						...state,
						videos: state.videos.map(video => video.video === updatedVideoId ? { ...video, name: action.payload.newName } : video)
					};
				}
			});
		}
		case TOGGLE_SHOW_VIDEO_CONTROLS: {
			return {
				...state,
				showVideoControls: action.payload
			};
		}
		case GET_CHANNEL_ARCHIVED_VIDEOS: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingArchivedVideos: true }),
				failure: (state) => ({ ...state, errorLoadingArchivedVideos: true }),
				success: (state) => {
					return { ...state, archivedVideos: action.payload.videos, archivedVideoCount: action.payload.count, errorLoadingArchivedVideos: false };
				},
				finish: (state) => ({ ...state, loadingArchivedVideos: false })
			});
		}
		default: return state;
	}
}