import React, { ChangeEventHandler, useCallback, useEffect, useMemo, Dispatch, SetStateAction, Fragment } from "react";
import { useParams } from "react-router";
import classNames from "classnames";

import { BrandliveEvent, Session } from '../../../types/working-model';
import { ParamsProps } from '../live-event';
import { clearErrorBoundaryComponents } from "../../../store/actions/event/event-actions";
import { useTranslate } from "../../../i18n/useTranslationModules";
import { useAppDispatch, useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { TEST_ERROR_BOUNDARIES } from "../../../utils/error-boundary";
import useTranslationRoute from "../hooks/use-translation-route";
import GroupedNavButton from "./grouped-nav-button";
import { useActiveModuleGroupsInSession, useIsNewModuleGrouping } from "hooks/session.hooks";
import { OptionalComponent } from "utils/optional-component";

import '../../../scss/live-event/base/session/session.scss';
import './above-the-fold/session-main-below-the-fold.scss';

interface GroupedNavButtonsEditorProps {
	singleSession?: boolean;
	previewBundle?: BrandliveEvent;
	previewSession?: Session;
	moduleGroupsRefs: React.RefObject<HTMLParagraphElement>[];
	selectedTabUuid: string | undefined;
	setSelectedTabUuid: Dispatch<SetStateAction<string | undefined>>
	autoScroll: boolean
	setAutoScroll: Dispatch<SetStateAction<boolean>>
	googleMeetJoined?: boolean;
}
const GroupedNavButtonsSession: React.FC<GroupedNavButtonsEditorProps> = ({
	moduleGroupsRefs,
	singleSession,
	previewBundle,
	previewSession,
	selectedTabUuid,
	setSelectedTabUuid,
	autoScroll,
	setAutoScroll,
	googleMeetJoined,
}) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const translationUpdates = useTypedSelector(state => state.LiveEventReducer.translationUpdates);
	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const dispatch = useAppDispatch();

	const { sessionUuid: sessionParamUuid, language }: ParamsProps = useParams();

	const isPreview = !!(previewBundle || previewSession);
	const event = isPreview ? previewBundle : eventBundle;

	const sessionUuid = singleSession ? event?.sessions?.[0]?.uuid : sessionParamUuid;

	const session = useMemo(() => {
		if (singleSession) {
			return previewBundle?.sessions?.[0] ? previewBundle?.sessions?.[0] : eventBundle?.sessions?.[0];
		}
		if (previewSession) {
			return previewSession;
		}
		return eventBundle?.sessions?.find(session => session.uuid === sessionUuid);
	}, [singleSession, eventBundle, previewBundle, sessionUuid, previewSession]);

	const activeModuleGroups =  useActiveModuleGroupsInSession(session, false, true, isModuleGroupingV2);

	useEffect(() => {
		if (!selectedTabUuid) {
			const defaultUUID = activeModuleGroups?.find(module => module.is_on && module.uuid)?.uuid;
			setSelectedTabUuid(defaultUUID);
		}

		// GET ALL THE TAB UUIDS FOR THE SESSION:
		const tabUuids = activeModuleGroups?.map(group => group.uuid);

		// IF THE SELECTED TAB UUID IS NOT IN THE TAB UUIDS, SET THE SELECTED TAB UUID TO THE FIRST TAB UUID:
		if (selectedTabUuid && tabUuids?.length && !tabUuids.includes(selectedTabUuid) && selectedTabUuid !== 'courses') {
			setSelectedTabUuid(tabUuids[0]);
		}
	}, [session, selectedTabUuid, setSelectedTabUuid]);

	// When we autoscroll, we don't want the tabs to auto switch when starting in a different section than the selected section
	useEffect(() => {
		setTimeout(() => {
			if (autoScroll) {
				setAutoScroll(false);
			}
		}, 1000);
	}, [autoScroll, setAutoScroll]);


	const translationNamespace = useTranslationRoute();
	const { t } = useTranslate(["session", translationNamespace]);

	const showIcons = session?.layout?.tabs?.show_icons ?? false;

	const handleTabChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
		if (TEST_ERROR_BOUNDARIES) dispatch(clearErrorBoundaryComponents());
		setSelectedTabUuid(target.value);
	}, [dispatch, setSelectedTabUuid]);

	const groupedNavButtons = useMemo(() => {
		if (!session) { return null; }

		const singleBreakoutRoom = session?.breakout_rooms?.length === 1;
		return activeModuleGroups?.map((group, index) => {
			const { type } = group;
			const hideBreakoutRoomTitle = singleBreakoutRoom && type === 'breakout' && googleMeetJoined;
			return hideBreakoutRoomTitle ? <Fragment key={index} /> : (
				<GroupedNavButton
					key={group.uuid}
					session={session}
					group={group}
					moduleGroupsRefs={moduleGroupsRefs}
					setAutoScroll={setAutoScroll}
					setSelectedTabUuid={setSelectedTabUuid}
					selectedTabUuid={selectedTabUuid}
					index={index}
					handleTabChange={handleTabChange}
					showIcons={showIcons}
					language={language}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activeModuleGroups,
		session?.module_grouping,
		session?.modules,
		handleTabChange,
		selectedTabUuid,
		showIcons,
		translationNamespace,
		t,
		language,
		translationUpdates, // need to force re-render when translationUpdates has fired
		googleMeetJoined,
	]);

	// display the profile bubble if there is no homepage but
	// registration is on or the session has more than 1 language
	return (
		<>
			{groupedNavButtons}
			<OptionalComponent display={session?.courses_settings?.enabled}>
				<li key={session?.uuid}
					id="courses-btf-nav-button"
					data-name={t('Courses', 'Courses')}
					className={classNames({ active: selectedTabUuid === 'courses' })}
				>
					<button
						onClick={() => requestAnimationFrame(() => {
							const el = document.getElementById('courses-btf-nav-button');
							if (el) {
								el.scrollIntoView( { behavior: 'smooth' });
							}
							setSelectedTabUuid('courses');
						})}
						className="clear no-style"
					>
						<span>{t('Courses', 'Courses')}</span>
					</button>
				</li>
			</OptionalComponent>
		</>
	);
};

export default GroupedNavButtonsSession;