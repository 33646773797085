import { CreateVideo, DownloadRecordedRequest, HomepageVideo, VideoCaption } from "../types/working-model";
import { Get, Post, PostJson, Put, PutJson } from "./helpers";

export function InsertVideo(token: string, video: CreateVideo): Promise<any> {
	return Post(`/v3/admin/channel/videos`, token, video);
}

export function UpdateVideo(token: string, video: HomepageVideo): Promise<any> {
	return Put(`/v3/admin/channel/videos`, token, video);
}

export function UploadedVideo(token: string, video: CreateVideo): Promise<any> {
	return Post('/v3/admin/channel/videos/uploaded', token, video);
}

export function GetChannelVideos(token: string, channel: number): Promise<HomepageVideo[]> {
	return Get(`/v3/admin/channel/videos/channel/${channel}`, token);
}

export function GetIbmDownloadLink(token: string, video_id: string): Promise<any> {
	return Get(`/v3/admin/channel/videos/ibm/download/${video_id}`, token);
}

export function GetWistiaDownloadLink(token: string, hashed_id: string): Promise<DownloadRecordedRequest> {
	return Get(`/v3/admin/channel/videos/wistia/download/${hashed_id}`, token);
}

export function GetRecordedVideos(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/channel/videos/recorded/${channel}`, token);
}

export function GetPendingDownloadRequests(token: string, channel: number): Promise<DownloadRecordedRequest[]> {
	return Get(`/v3/admin/channel/videos/recorded/download/pending/${channel}`, token);
}

export function GetRecordedVideoDownload(token: string, stream_recording: string, channel: number, forceFailure?: boolean): Promise<DownloadRecordedRequest> {
	return Get(`/v3/admin/channel/videos/recorded/download/${stream_recording}/${channel}${forceFailure ? `?testFail=1` : ''}`, token);
}

export function GetRecordedMuxVideoDownload(token: string, videoUuid: string, channel: number, forceFailure?: boolean): Promise<DownloadRecordedRequest> {
	return Get(`/v3/admin/channel/videos/recorded/download/mux/${videoUuid}/${channel}${forceFailure ? `?testFail=1` : ''}`, token);
}

export function DeleteRecordedVideos(videoUuids: string[], token: string, channel: number): Promise<any> {
	return Post(`/v3/admin/channel/videos/delete-recorded/${channel}`, token, { videoUuids });
}

export function DeleteUploadedVideos(videoUuids: string[], token: string, channel: number): Promise<any> {
	return Post(`/v3/admin/channel/videos/delete-uploaded/${channel}`, token, { videoUuids });
}

export function GetVideoCaptionFilesForUploadedVideo(token: string, channelId: number, videoId: number): Promise<VideoCaption[]> {
	return Get(`/v3/admin/channel/videos/video-caption-files/uploaded/${channelId}/${videoId}`, token);
}

export function GetVideoCaptionFilesForRecordedVideo(token: string, channelId: number, recordingId: number): Promise<VideoCaption[]> {
	return Get(`/v3/admin/channel/videos/video-caption-files/recorded/${channelId}/${recordingId}`, token);
}

type AddVideoCaptionFileInput = {
	token: string,
	channel: number,
	s3Url: string,
	fileName: string,
	language: string,
	videoId?: number,
	recordingId?: number
}
export function AddVideoCaptionFile({ token, channel, s3Url, fileName, language, videoId, recordingId }: AddVideoCaptionFileInput): Promise<void> {
	return PostJson({
		path: '/v3/admin/channel/videos/add-video-caption-file',
		token,
		data: { channel, s3Url, fileName, language, videoId, recordingId },
	});
}

export function UpdateVideoCaptionFileSource(token: string, channel: number, s3Url: string, fileName: string, videoCaptionId: number): Promise<void> {
	return PutJson({
		path: '/v3/admin/channel/videos/update-video-caption-file-source',
		token,
		data: { channel, s3Url, fileName, videoCaptionId },
	});
}

export function UpdateVideoName(token: string, videoId: number, newName: string): Promise<any> {
	return Put(`/v3/admin/channel/videos/name/${videoId}`, token, { newName }, true);
}